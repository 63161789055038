import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Button, Header, Input, TextArea, TypeButton } from "../../components";
import { isEmpty, useAppDispatch, useAppSelector } from "../../utils";
import { PROVINCE } from "../../constants/_province";
import { CITY_DISTRICT } from "../../constants/_city_and_district";
import { IFarmerStore } from "../../redux/FarmerStore/farmerStore.types";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../redux/store";
import {
  editFarmerStore,
  getFarmerStore,
} from "../../redux/FarmerStore/farmerStore.actions";
import { ROUTE } from "../../constants";
import Select from "react-select";

const EditFarmerShop: React.FC = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const gFarmerStore = useAppSelector((state: RootState) => state.farmerStore);
  const farmerStore: IFarmerStore = gFarmerStore.farmerStore;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<IFarmerStore>();

  interface IDropdown {
    label: string | number;
    value: string | number;
  }

  type FormData = {
    province: IDropdown | null;
    city_or_district: IDropdown | null;
  };

  const {
    control: DropdownControl,
    watch: watchDropdown,
    setValue: setValueDropdown,
  } = useForm<FormData>();

  const province = watchDropdown("province");
  const provinceId = watch("province");
  const city_or_district = watchDropdown("city_or_district");

  const [listProvince, setListProvince] = useState<IDropdown[]>([]);
  const [listCity, setListCity] = useState<IDropdown[]>([]);

  useEffect(() => {
    setListProvince(PROVINCE);
  }, []);

  useEffect(() => {
    dispatch(getFarmerStore(id as string | number));
  }, [dispatch, id]);

  useEffect(() => {
    if (!isEmpty(farmerStore)) {
      setValue("id", farmerStore?.id);
      setValue("name", farmerStore?.name);
      setValue("address", farmerStore?.address);
      setValue("province", farmerStore?.province);
      setValue("city_or_district", farmerStore?.city_or_district);
      setValue("owner_name", farmerStore?.owner_name);
      setValue("phone", farmerStore?.phone);

      const setterProvince = PROVINCE.filter(
        (val) => (val.value as string) === (farmerStore?.province as string)
      );
      if (!isEmpty(setterProvince)) {
        setValueDropdown("province", setterProvince[0]);
      }

      const setterCityOrDistrict = CITY_DISTRICT.filter(
        (val) =>
          (val.value as string) === (farmerStore?.city_or_district as string)
      );

      if (!isEmpty(setterCityOrDistrict)) {
        setValueDropdown("city_or_district", setterCityOrDistrict[0]);
      }
    }
  }, [farmerStore]);

  useEffect(() => {
    if (!isEmpty(province)) {
      setValue("province", province?.value as string);
      const cityByProvince = CITY_DISTRICT.filter(
        (val) => (val.province_id as string) === (province?.value as string)
      );
      setListCity(cityByProvince);

      if (province?.value !== provinceId) {
        setValueDropdown("city_or_district", null);
        setValue("city_or_district", null);
      }
    }
    if (!isEmpty(city_or_district)) {
      setValue("city_or_district", city_or_district?.value as string);
    }
  }, [province, city_or_district, setValue]);

  const addNewFarmerShop: SubmitHandler<IFarmerStore> = async (data) => {
    await dispatch(editFarmerStore(data));
    navigate(ROUTE.FARMER_SHOPS);
  };

  return (
    <div className="px-5">
      <div className="mt-4" />
      <Header title={`Edit Toko`} />
      <form onSubmit={handleSubmit(addNewFarmerShop)}>
        {/* Address */}
        <Input
          errorMessage={errors.name?.message as string}
          id="name"
          isError={!isEmpty(errors.name)}
          labelTop="Nama Toko"
          register={register("name", {
            required: "Nama Toko wajib diisi",
          })}
        />
        <TextArea
          errorMessage={errors.address?.message as string}
          id="address"
          isError={!isEmpty(errors.address)}
          labelTop="Alamat Lengkap"
          styleWrapper="mt-6"
          register={register("address", {
            required: "Alamat wajib diisi",
          })}
        />
        <div className={`flex flex-col gap-2 mt-6`}>
          <label className="text-[16px] font-bold">Provinsi</label>
          <Controller
            name="province"
            control={DropdownControl}
            render={({ field }) => (
              <Select
                {...field}
                options={listProvince}
                isSearchable={true}
                placeholder="Pilih Provinsi"
                className="w-full rounded-md text-lg bg-white border-[#CCC]"
                value={{ label: field.value?.label }}
              />
            )}
          />
        </div>
        <input {...register("province", { required: true })} hidden />

        <div className={`flex flex-col gap-2 mt-6`}>
          <label className="text-[16px] font-bold">Kota</label>
          <Controller
            name="city_or_district"
            control={DropdownControl}
            render={({ field }) => (
              <Select
                {...field}
                options={listCity}
                isSearchable={true}
                placeholder="Pilih Kota"
                className="w-full rounded-md text-lg bg-white border-[#CCC]"
                value={{ label: field.value?.label }}
              />
            )}
          />
        </div>
        <input {...register("city_or_district", { required: true })} hidden />

        {/* Contact */}
        <h1 className="font-bold text-xl text-[#333] my-12">Kontak</h1>
        <Input
          errorMessage={errors.owner_name?.message as string}
          id="owner-name"
          isError={!isEmpty(errors.owner_name)}
          labelTop="Nama"
          register={register("owner_name", {
            required: "Nama Wajib Diisi",
          })}
          styleWrapper="mt-0"
        />
        <Input
          errorMessage={errors.phone?.message as string}
          id="phone"
          isError={!isEmpty(errors.phone)}
          labelTop="No. HP / WA"
          register={register("phone", {
            required: "Nomor HP/WA Wajib Diisi",
          })}
          styleWrapper="mt-6"
        />

        <Button type={TypeButton.submit} title="Simpan" className="my-12" />
      </form>
    </div>
  );
};

export default EditFarmerShop;
