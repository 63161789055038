import React from "react";
import { ArrowLeftSolid } from "../../../assets";
import { useNavigate } from "react-router-dom";

interface HeaderProps {
  customBack?: boolean;
  onPress?: () => void;
  title: string;
  rightMenuTitle: string;
  onPressRightMenu?: () => void;
}

const HeaderWithButton: React.FC<HeaderProps> = ({
  customBack = false,
  onPress = () => {},
  title,
  rightMenuTitle,
  onPressRightMenu,
}) => {
  const navigate = useNavigate();

  const defaultBack = (): void => {
    navigate(-1);
  };

  return (
    <div className="flex flex-1 flex-row items-center">
      <img
        src={ArrowLeftSolid}
        height={20}
        width={20}
        alt="arrow-left-solid"
        className="cursor-pointer"
        onClick={() => (customBack ? onPress() : defaultBack())}
      />
      <div className="mx-4 flex flex-1 flex-row justify-between items-center">
        <p className="font-bold text-[#333333]">{title}</p>
        <u
          className="text-[#001049] text-base cursor-pointer"
          onClick={onPressRightMenu}
        >
          {rightMenuTitle}
        </u>
      </div>
    </div>
  );
};

export default HeaderWithButton;
