import React from "react";
import ActivityCheckinShopVisitNew from "./Layout/ShopVisitNew";
import { useSearchParams } from "react-router-dom";

const ActivityCheckinNew: React.FC = (): JSX.Element => {
  const [query] = useSearchParams();
  let type: string | null = query.get("type");

  enum activityType {
    shopVisit = "shop-visit",
    farmerMeeting = "farmer-meeting",
    demoPlot = "demo-plot",
  }

  return (
    <div className="px-5">
      {type === activityType.shopVisit && <ActivityCheckinShopVisitNew />}
    </div>
  );
};

export default ActivityCheckinNew;
