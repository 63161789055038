import firebase from "firebase/compat/app";
import "firebase/compat/auth";

// Firebase configuration containing API keys and settings
const firebaseConfig = {
  apiKey: "AIzaSyCD6Rb6wYT-hmblVlAlI6EW9mL-j5qcHh8",
  authDomain: "alishan-sales-v2.firebaseapp.com",
  projectId: "alishan-sales-v2",
  storageBucket: "alishan-sales-v2.appspot.com",
  messagingSenderId: "622089830515",
  appId: "1:622089830515:web:06b1fa7ddc4693e4baea06",
  measurementId: "G-PVRQHYKT7L",
};

// Initialize the Firebase app with the provided configuration
firebase.initializeApp(firebaseConfig);

// Access the Firebase authentication service
export let f_auth = firebase.auth();

// Access the Firebase authentication service SDK
export let f_auth_sdk = firebase.auth;
