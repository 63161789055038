import React from "react";
import { Header } from "../../components";

const NotificationDetail: React.FC = () => {
  return (
    <div className="px-5">
      <div className="mt-4" />
      <Header title={`Promo sprayer bulan juli`} />
      <div className="mt-11 text-base text-[#333333]">
        <p>
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book.
        </p>
      </div>
    </div>
  );
};

export default NotificationDetail;
