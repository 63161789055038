import React, { useEffect } from "react";
import ShopVisitNoteEdit, { TypeShopVisitNote } from "./Layout/EditNote";
import { isEmpty, useAppDispatch } from "../../utils";
import { useSearchParams } from "react-router-dom";
import ShopVisitNoteReadOnly from "./Layout/ReadOnlyNote";
import { getDetailStoreVisit } from "../../redux/Activity/activity.actions";

const ShopVisitNote: React.FC = (): JSX.Element => {
  const [query] = useSearchParams();
  const dispatch = useAppDispatch();
  let type: string | null = query.get("type");

  useEffect(() => {
    dispatch(getDetailStoreVisit());
  }, [dispatch]);

  return (
    <div className="px-5">
      {[TypeShopVisitNote.new, TypeShopVisitNote.edit].indexOf(
        type as TypeShopVisitNote
      ) > -1 && (
        <ShopVisitNoteEdit
          type={
            type === TypeShopVisitNote.new
              ? TypeShopVisitNote.new
              : TypeShopVisitNote.edit
          }
        />
      )}
      {isEmpty(type) && <ShopVisitNoteReadOnly />}
    </div>
  );
};

export default ShopVisitNote;
