export const SET_DEPOSIT_MONEY = "SET_DEPOSIT_MONEY";


export interface IDeposit {
  sales_id: number | string,
  store_visit_id: number | string,
  type: string,
  nominal: string | number,
  note: string | null,
  image: string | null,
  id: number | string,
  photo_full_url: string | null
}

export interface IDepositForm {
  type: string,
  nominal: string | number,
  note: string | null,
  image: File,
}

export interface DepositMoneyInterface {
  depositMoney: IDeposit | null
}