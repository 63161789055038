import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Input } from "../../components";
import { ROUTE } from "../../constants";
import { RootState } from "../../redux/store";
import { f_auth, f_auth_sdk } from "../../services/firebaseConfig";
import {
  isEmpty,
  secondToDuration,
  useAppDispatch,
  useAppSelector,
} from "../../utils";
import { setAuthed } from "../../redux/Auth/auth.actions";
import { useNavigate } from "react-router-dom";

const ConfirmOTP: React.FC = () => {
  // Hooks and States
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authed = useAppSelector((state: RootState) => state.auth);
  const [countdown, setCountdown] = useState<number>(30);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [otp, setOtp] = useState<string>("");
  const [isErrorOtp, setIsErrorOtp] = useState<boolean>(false);

  // Callback to handle countdown
  const handleCountdown = useCallback(() => {
    setCountdown((prev) => prev - 1);
  }, []);

  // Effect to start countdown and clean up on unmount
  useEffect(() => {
    const _intervalId: NodeJS.Timeout = setInterval(handleCountdown, 1000);
    setIntervalId(_intervalId);

    return () => {
      clearInterval(_intervalId);
    };
  }, [handleCountdown]);

  // Effect to clear interval when countdown reaches 0
  useEffect(() => {
    if (countdown <= 0 && !isEmpty(intervalId)) {
      clearInterval(intervalId as NodeJS.Timeout);
    }
  }, [countdown, intervalId]);

  // Callback to handle signing in with OTP
  const handleSignInWithCode = useCallback(async () => {
    try {
      const credential = f_auth_sdk.PhoneAuthProvider.credential(
        authed.verificationId,
        otp
      );
      await f_auth.signInWithCredential(credential);
      dispatch(setAuthed(true));
      navigate(ROUTE.BASE_ROUTE);
    } catch (error: any) {
      console.error("Error signing in:", error.message);
      setIsErrorOtp(true);
    }
  }, [authed.verificationId, dispatch, navigate, otp]);

  // Effect to handle OTP input and signing in
  useEffect(() => {
    setIsErrorOtp(false);

    if (!isEmpty(otp) && otp.length === 6) {
      handleSignInWithCode();
    }
  }, [otp, handleSignInWithCode]);

  // Memoized format for countdown duration
  const formatDuration = useMemo(
    () => secondToDuration(countdown),
    [countdown]
  );

  // Callback to resend OTP code
  const resendCode = (): void => {
    setCountdown(60);
    const interval: NodeJS.Timer = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);
    setIntervalId(interval);
  };

  // Render the component
  return (
    <div className="px-5">
      <h1 className="text-[24px] mt-10">Verifikasi</h1>
      <p className="text-lg mt-4 max-w-[315px]">
        Masukkan kode OTP yang dikirimkan ke {authed.phone}
      </p>
      <Input
        id="otp"
        labelTop="Kode OTP"
        isCenterInput={true}
        isError={isErrorOtp}
        errorMessage="Kode OTP tidak sesuai, atau sudah expired. Silakan coba lagi."
        name="otp"
        maxLength={6}
        onchange={(e: ChangeEvent<HTMLInputElement>): void =>
          setOtp(e.target.value)
        }
        value={otp}
      />
      {countdown > 0 && (
        <p className="text-center mt-8">
          Kirim ulang kode OTP setelah {formatDuration}
        </p>
      )}

      {countdown === 0 && (
        <div className="mt-8 text-center">
          <button className="underline" onClick={resendCode}>
            Kirim ulang kode OTP
          </button>
        </div>
      )}
    </div>
  );
};

export default ConfirmOTP;
