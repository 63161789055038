import axios from "axios";
import querystring from "qs";
import AsyncStorage from "@react-native-async-storage/async-storage";

class Api {
  constructor(baseUrl, middleware = () => {}) {
    this.baseUrl = baseUrl;
    this.client = axios.create();
    middleware(this.client);
    this.client.interceptors.request.use((config) => config);
    this.client.interceptors.response.use((response) => {
      return response;
    });
  }
  put(url, dat, qs = {}, config = {}, file = false) {
    const obj = file
      ? { qs, formData: dat, config }
      : { qs, json: dat, config };
    return this.sendRequest("PUT", url, obj);
  }
  get(url, qs = {}, config = {}) {
    return this.sendRequest("GET", url, { qs, config });
  }
  post(url, dat, qs = {}, config = {}, file = true) {
    const obj = file
      ? { qs, formData: dat, config }
      : { qs, json: dat, config };
    return this.sendRequest("POST", url, obj);
  }
  delete(url, qs = {}, config = {}) {
    return this.sendRequest("DELETE", url, { qs, config });
  }
  async sendRequest(requestMethod, url, data = {}) {
    try {
      const token = await AsyncStorage.getItem("accessToken");
      const headers = data.config ? data.config.headers || {} : {};
      if (token && url !== "/oauth/token") {
        headers.Authorization = `Bearer ${token}`;
      }
      this.client.defaults.headers.common = headers;
      const json = await this.client.request(
        {
          method: requestMethod,
          url,
          baseURL: this.baseUrl,
          params: data.qs,
          data: data.json || querystring.stringify(data.form) || data.formData,
          headers: Object.assign(
            headers,
            data.json
              ? { "Content-type": "application/json" }
              : data.form
              ? { "Content-Type": "application/x-www-form-urlencoded" }
              : { "Content-Type": "multipart/form-data" }
          ),
          timeout: 60 * 1000,
          paramsSerializer: (params) => querystring.stringify(params),
          validateStatus: function (status) {
            return status >= 200 && status < 300;
            // return [200, 201, 401, 404, 409].indexOf(status) >= 0 ? true : false; // Resolve only if the status code is less than 500
          },
        },
        data.config
      );
      return json;
    } catch (e) {
      console.log("error occur api js", e.response.data);
    }
  }
}

const env = process.env;
const api_url = env.REACT_APP_API_URL;

const api = new Api(api_url, (instance) => {});
export default api;
