"use client";
import React, { ChangeEvent, Key } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { isEmpty } from "../../../utils";

interface ISelect {
  label: string | number;
  value: string | number;
}

interface SelectProps {
  data?: ISelect[];
  errorMessage?: string;
  id?: string;
  isError?: boolean;
  labelBottom?: string;
  labelTop?: string;
  name?: string;
  onchange?: (e: ChangeEvent<HTMLSelectElement>) => void;
  placeholder: string;
  register?: UseFormRegisterReturn<any> | null;
  styleWrapper?: string;
  value?: string;
}

const Select: React.FC<SelectProps> = ({
  data = [],
  errorMessage,
  id,
  isError = false,
  labelBottom,
  labelTop,
  name,
  onchange,
  placeholder,
  register,
  styleWrapper = "mt-16",
  value,
}): JSX.Element => {
  const classNameLabelBottom = `text-[14px] ${
    isError ? "text-[#FF0000]" : "text-[#999999]"
  }`;

  return (
    <div className={`flex flex-col gap-2 ${styleWrapper}`}>
      <label className="text-[16px] font-bold">{labelTop}</label>

      {!register && (
        <select
          className="w-full p-2 border rounded-md text-lg bg-white border-[#CCC] focus:border-[#001049]"
          id={id}
          name={name}
          onChange={onchange}
          value={value}
        >
          <option value="---" disabled selected>
            {placeholder}
          </option>
          {data.map((data: ISelect, key: Key) => {
            return (
              <option key={key} value={data.value}>
                {data.label}
              </option>
            );
          })}
        </select>
      )}

      {register && (
        <select
          className="w-full p-2 border rounded-md text-lg bg-white border-[#CCC] focus:border-[#001049]"
          defaultValue={value}
          id={id}
          {...register}
        >
          <option value="---" disabled>
            {placeholder}
          </option>
          {data.map((data: ISelect, key: Key) => {
            return (
              <option key={key} value={data.value}>
                {data.label}
              </option>
            );
          })}
        </select>
      )}

      {(!isEmpty(labelBottom) || !isEmpty(errorMessage)) && (
        <p className={classNameLabelBottom}>
          {isError ? errorMessage : labelBottom}
        </p>
      )}
    </div>
  );
};

export default Select;
