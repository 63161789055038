import React from "react";
import { BoxListNotification, Header } from "../../components";

const NotificationList: React.FC = () => {
  return (
    <div className="px-5">
      <div className="mt-4" />
      <Header title={`Inbox`} />
      <BoxListNotification />
      <BoxListNotification />
    </div>
  );
};

export default NotificationList;
