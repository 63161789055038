import React, { Key, useEffect, useState } from "react";
import { BaseModal, HeaderWithButton } from "../../components";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../../constants";
import {
  formatRupiah,
  isEmpty,
  useAppDispatch,
  useAppSelector,
} from "../../utils";
import { RootState } from "../../redux/store";
import { ISalesOrderDetail } from "../../redux/SalesOrder/sales_order.types";
import {
  deleteSalesOrder,
  getDetailSalesOrder,
} from "../../redux/SalesOrder/sales_order.actions";

const SalesOrder: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const salesOrder = useAppSelector((state: RootState) => state.salesOrder);
  const sales_orders = salesOrder.sales_orders;
  const grand_total = salesOrder.grand_total;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState<
    string | number | null
  >(null);

  const showModal = (id: number | string) => {
    setSelectedDeleteId(id);
    setIsModalOpen(true);
  };

  const deleteOrder = async () => {
    await dispatch(deleteSalesOrder(selectedDeleteId as number));
    await dispatch(getDetailSalesOrder());
    setIsModalOpen(false);
  };

  useEffect(() => {
    dispatch(getDetailSalesOrder());
  }, [dispatch]);

  const CardOrder: React.FC<ISalesOrderDetail> = ({
    id,
    inven_name,
    qty,
    price,
    total_price,
    inventory_id,
  }): JSX.Element => {
    return (
      <div className="border-b border-[#999] py-7">
        <h3 className="text-[#666] font-bold text-sm">Nama barang</h3>
        <p className="text-[#333] text-sm mt-1">{inven_name}</p>
        <div className="mt-4 flex flex-1 flex-row gap-8">
          <div>
            <h4 className="text-[#666] font-bold text-sm">Qty</h4>
            <p className="text-[#333] text-sm mt-1">{qty}</p>
          </div>
          <div>
            <h4 className="text-[#666] font-bold text-sm">Harga Satuan</h4>
            <p className="text-[#333] text-sm mt-1">
              {formatRupiah(price as number)}
            </p>
          </div>
          <div>
            <h4 className="text-[#666] font-bold text-sm">Total</h4>
            <p className="text-[#333] text-sm mt-1">
              {formatRupiah(total_price as number)}
            </p>
          </div>
        </div>
        {/* Action Button */}
        <div className="flex mt-6 justify-end">
          <button
            className="underline text-[#333333] mr-3"
            onClick={async () => {
              navigate(ROUTE.SALES_ORDER_EDIT(inventory_id as number));
            }}
          >
            Edit
          </button>
          <button
            className="underline text-[#FF0000]"
            onClick={() => showModal(inventory_id)}
          >
            Hapus
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="px-5">
      <div className="mt-4" />
      <HeaderWithButton
        title={`Sales Order`}
        rightMenuTitle="TAMBAH"
        onPressRightMenu={() => navigate(`${ROUTE.SALES_ORDER_NEW}`)}
      />
      {isEmpty(sales_orders) && (
        <p className="text-[#999] text-base text-center mt-14">
          Belum ada Sales Order
        </p>
      )}
      {!isEmpty(sales_orders) && <div className="mt-7" />}
      {!isEmpty(sales_orders) &&
        sales_orders.map((data: ISalesOrderDetail, key: Key) => {
          return <CardOrder key={key} {...data} />;
        })}
      {!isEmpty(sales_orders) && (
        <p className="text-[#333] font-bold text-base mt-7">
          Grand Total: {formatRupiah(grand_total)}{" "}
        </p>
      )}
      <BaseModal
        heading="Konfirmasi"
        content="Yakin ingin menghapus pesanan ini ?"
        isOpen={isModalOpen}
        confirmText="Ya, hapus"
        handleConfirmModal={deleteOrder}
        handleCloseModal={() => {
          setSelectedDeleteId(null);
          setIsModalOpen(false);
        }}
      />
    </div>
  );
};

export default SalesOrder;
