import React, { useEffect, useState } from "react";
import { Header, BaseModal } from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTE } from "../../constants";
import {
  getDistrictNameById,
  getProvinceNameById,
  useAppDispatch,
  useAppSelector,
} from "../../utils";
import { RootState } from "../../redux/store";
import {
  deleteFarmerStore,
  getFarmerStore,
} from "../../redux/FarmerStore/farmerStore.actions";

const FarmerShop: React.FC = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const gFarmerStore = useAppSelector((state: RootState) => state.farmerStore);
  const farmerStore = gFarmerStore.farmerStore;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getFarmerStore(id as string | number));
  }, [dispatch, id]);

  const actionDelete = async () => {
    await dispatch(deleteFarmerStore(id as string));
    navigate(ROUTE.FARMER_SHOPS);
  };

  return (
    <div className="px-5">
      <div className="mt-4" />
      <Header title={farmerStore?.name} />
      <div className="mt-11">
        {/* Address */}
        <p className="text-base text-[#333333]">
          {farmerStore?.address} <br />{" "}
          {getDistrictNameById(farmerStore?.city_or_district)},{" "}
          {getProvinceNameById(farmerStore?.province)}
        </p>
        {/* Contact */}
        <div className="mt-4">
          <label className="text-base font-bold text-[#333333]">Kontak: </label>
          <p className="text-base text-[#333333]">{farmerStore?.owner_name}</p>
          <p className="text-base text-[#333333] underline">
            {farmerStore?.phone}
          </p>
        </div>
        {/* Action Button */}
        <div className="flex mt-8 justify-end">
          <button
            className="underline text-[#333333] mr-3"
            onClick={() => navigate(ROUTE.EDIT_FARMER_SHOP(id as string))}
          >
            Edit
          </button>
          <button
            className="underline text-[#FF0000]"
            onClick={() => setIsModalOpen(true)}
          >
            Hapus
          </button>
        </div>
        {/* Modal */}
        <BaseModal
          heading="Konfirmasi"
          content="Yakin ingin menghapus data ini ?"
          isOpen={isModalOpen}
          handleConfirmModal={() => actionDelete()}
          handleCloseModal={() => setIsModalOpen(false)}
        />
      </div>
    </div>
  );
};

export default FarmerShop;
