import React, { useCallback, useEffect, useState } from "react";
import {
  ClockRegular,
  IconCatatanKunjungan,
  IconCheckout,
  IconSalesOrder,
  IconTitipBayar,
} from "../../assets";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../../constants";
import { isEmpty, useAppDispatch, useAppSelector } from "../../utils";
import { BaseModal } from "../../components";
import {
  actionCheckout,
  getDetailStoreVisit,
  getLocation,
} from "../../redux/Activity/activity.actions";
import { RootState } from "../../redux/store";
import moment, { Moment } from "moment";

const ShopVisit: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const activity = useAppSelector((state: RootState) => state.activity);
  const storeVisit = activity.storeVisit;
  const mTime: Moment = moment(storeVisit?.checkin_time);

  const [isSameOrAfter, setIsSameOrAfter] = useState(false);

  const [isModalCheckoutOpen, setIsModalCheckoutOpen] =
    useState<boolean>(false);

  enum TypeShopVisitMenu {
    salesOrder = "sales-order",
    depositMoney = "deposit-money",
    shopVisitNote = "shop-visit-note",
    checkout = "checkout",
  }
  const ShopVisitMenu: React.FC<{ type: TypeShopVisitMenu }> = ({
    type,
  }): JSX.Element => {
    let IconShopVisitMenu: string | null = IconSalesOrder;
    let title: string | null = "Sales Order";
    let routeName: string | null = null;

    switch (type) {
      case TypeShopVisitMenu.depositMoney:
        IconShopVisitMenu = IconTitipBayar;
        title = "Titipan Pembayaran";
        routeName = ROUTE.DEPOSIT_MONEY;
        break;
      case TypeShopVisitMenu.shopVisitNote:
        IconShopVisitMenu = IconCatatanKunjungan;
        title = "Catatan Kunjungan";
        routeName = ROUTE.SHOP_VISIT_NOTE;
        break;
      case TypeShopVisitMenu.salesOrder:
        IconShopVisitMenu = IconSalesOrder;
        title = "Sales Order";
        routeName = ROUTE.SALES_ORDER;
        break;
      default:
        IconShopVisitMenu = IconCheckout;
        title = "Check-out";
        break;
    }

    const onClick = () => {
      if (!isEmpty(routeName)) {
        navigate(routeName as string);
        return;
      }

      setIsModalCheckoutOpen(true);
      return;
    };

    return (
      <div
        className="mt-8 flex flex-1 flex-row items-center cursor-pointer"
        onClick={onClick}
      >
        <img
          src={IconShopVisitMenu}
          alt="img-shop-visit-menu"
          width={40}
          height={40}
        />
        <p className="ml-[10px] text-[#333] text-base">{title}</p>
      </div>
    );
  };

  useEffect(() => {
    dispatch(getDetailStoreVisit());
    dispatch(getLocation());
  }, [dispatch]);

  const checkout = async () => {
    await dispatch(actionCheckout());
    navigate(ROUTE.BASE_ROUTE);
  };

  const validateCheckout = useCallback(async () => {
    if (!isEmpty(mTime)) {
      const mTimeNow = moment();
      const mTimeExpired = mTime.add(1, "day");
      const _isSameOrAfter = moment(mTimeNow).isSameOrAfter(
        mTimeExpired,
        "date"
      );
      if (_isSameOrAfter) {
        setIsSameOrAfter(true);
      }
    }
  }, [mTime]);

  useEffect(() => {
    validateCheckout();
  }, [validateCheckout]);

  useEffect(() => {
    if (isSameOrAfter) {
      checkout();
    }
  }, [isSameOrAfter]);

  return (
    <div className="px-5">
      <h1 className="text-[#333] text-xl font-bold my-4">
        {storeVisit?.store_name}
      </h1>
      <div className="flex flex-1 flex-row items-center">
        <img src={ClockRegular} alt="clock-regular" />
        <p className="ml-2 text-[#333] text-[15px]">
          Check-in: {mTime.format("HH:mm:ss")}
        </p>
      </div>
      <div className="mt-6">
        <ShopVisitMenu type={TypeShopVisitMenu.salesOrder} />
        <ShopVisitMenu type={TypeShopVisitMenu.depositMoney} />
        <ShopVisitMenu type={TypeShopVisitMenu.shopVisitNote} />
        <ShopVisitMenu type={TypeShopVisitMenu.checkout} />
      </div>
      <BaseModal
        heading="Konfirmasi"
        content="Yakin ingin checkout ?"
        isOpen={isModalCheckoutOpen}
        confirmText="Ya, check-out"
        handleConfirmModal={() => checkout()}
        handleCloseModal={() => setIsModalCheckoutOpen(false)}
      />
    </div>
  );
};

export default ShopVisit;
