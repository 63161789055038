import React, { useCallback, useEffect, useState } from "react";
import { Header } from "../../components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getCookie, isEmpty, useAppDispatch } from "../../utils";
import { COOKIES, ROUTE } from "../../constants";
import api from "../../services/api";
import { API_ROUTE } from "../../constants/_api_route";
import { setIsLoading } from "../../redux/Auth/auth.actions";
import moment from "moment";

const ActivityList: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const dispatch = useAppDispatch();
  let type: string | null = query.get("type");

  const [kunjungan, setKunjungan] = useState([]);

  const fetchKunjungan = useCallback(async () => {
    dispatch(setIsLoading(true));
    const uuid = getCookie(COOKIES.AUTH_UUID);
    const response = await api.post(API_ROUTE.STORE_VISIT.LIST, { uuid: uuid });
    if (response) {
      setKunjungan(response.data.data);
    }
    dispatch(setIsLoading(false));
    console.log(response);
  }, [dispatch]);

  useEffect(() => {
    if (isEmpty(type) || type === "1") {
      fetchKunjungan();
    }
  }, [type, fetchKunjungan]);

  useEffect(() => {
    console.log("kunjungan: ", kunjungan);
  }, [kunjungan]);

  return (
    <>
      <div className="px-5 mt-4">
        <Header title={`Riwayat Aktivitas`} />

        <div className="flex flex-row justify-between mt-6">
          <div
            className={`w-full text-center cursor-pointer ${
              isEmpty(type) || type === "1" ? "border-b-2 border-[#001049]" : ""
            }`}
            onClick={() => navigate(`${ROUTE.ACTIVITY_LIST}`)}
          >
            <p className="text-[#333] text-base">Kunjungan Toko</p>
          </div>
          <div
            className={`w-full text-center cursor-pointer ${
              type === "2" ? "border-b-2 border-[#001049]" : ""
            }`}
            onClick={() => navigate(`${ROUTE.ACTIVITY_LIST}?type=2`)}
          >
            <p className="text-[#333] text-base">Demo Plot</p>
          </div>
          <div
            className={`w-full text-center cursor-pointer ${
              type === "3" ? "border-b-2 border-[#001049]" : ""
            }`}
            onClick={() => navigate(`${ROUTE.ACTIVITY_LIST}?type=3`)}
          >
            <p className="text-[#333] text-base">Temu Tani</p>
          </div>
        </div>

        <div>
          {(isEmpty(type) || type === "1") && isEmpty(kunjungan) && (
            <p className="text-[#333] text-base text-center mt-14">
              Belum ada data
            </p>
          )}
          {!isEmpty(type) && type !== "1" && (
            <p className="text-[#333] text-base text-center mt-14">
              Belum ada data
            </p>
          )}
          {(isEmpty(type) || type === "1") &&
            !isEmpty(kunjungan) &&
            // eslint-disable-next-line array-callback-return
            kunjungan.map(
              (
                item: {
                  store_name: string;
                  checkin_time: string;
                  checkout_time: string;
                },
                key
              ) => (
                <div className="bg-white shadow-md p-4 rounded mt-2">
                  <div className="flex flex-row justify-between items-center">
                    <p className="text-[#333] text-base">{item.store_name}</p>
                    <p className="text-[#333] text-sm">
                      {moment(item.checkin_time).format("DD MMMM Y")}
                    </p>
                  </div>
                  <div className="flex flex-row">
                    <p className="text-[#999] text-sm">
                      {moment(item.checkin_time).format("HH:mm")}
                    </p>
                    <p className="text-[#999] text-sm">&nbsp; - &nbsp;</p>
                    <p className="text-[#999] text-sm">
                      {moment(item.checkout_time).format("HH:mm")}
                    </p>
                  </div>
                </div>
              )
            )}
        </div>
      </div>
    </>
  );
};

export default ActivityList;
