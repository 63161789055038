export const ROUTE_PATH = {
  BASE_ROUTE: "/",
  SPLASH: "/",
  SIGN_IN_PHONE: "/auth-phone",
  CONFIRM_OTP: "/confirm-otp",
  NOTIFICATIONS: "/notifications",
  NOTIFICATION_DETAIL: "/notifications/:id",
  FARMER_SHOPS: "/farmer-shops",
  FARMER_SHOP_DETAIL: "/farmer-shops/:id",
  NEW_FARMER_SHOP: "/farmer-shops/new",
  EDIT_FARMER_SHOP: "/farmer-shops/:id/edit",
  ACTIVITY_CHECKIN: "/activity-checkin",
  ACTIVITY_LIST: "/activity-list",
  ACTIVITY_CHECKIN_NEW: "/activity-checkin/new",
  SELFIE_GUIDE: "/activity-checkin/selfie-guide",
  SELFIE_CHECKIN: "/activity-checkin/selfie",
  SHOP_VISIT: "/shop-visit",
  SHOP_VISIT_NOTE: "/shop-visit/note",
  SALES_ORDER: "/sales-order",
  SALES_ORDER_NEW: "/sales-order/new",
  SALES_ORDER_EDIT: "/sales-order/:id/edit",
  DEPOSIT_MONEY: "/deposit-money",
};

export const ROUTE = {
  ...ROUTE_PATH,
  NOTIFICATION_DETAIL: (id: number | string) => `/notifications/${id}`,
  FARMER_SHOP_DETAIL: (id: number | string) => `/farmer-shops/${id}`,
  EDIT_FARMER_SHOP: (id: number | string) => `/farmer-shops/${id}/edit`,
  SALES_ORDER_EDIT: (id: number | string) => `/sales-order/${id}/edit`,
};
