import React, { useEffect } from "react";
import { Button, Header, TextArea, TypeButton } from "../../../components";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { isEmpty, useAppDispatch, useAppSelector } from "../../../utils";
import { ROUTE } from "../../../constants";
import { RootState } from "../../../redux/store";
import { saveNote } from "../../../redux/Activity/activity.actions";

export enum TypeShopVisitNote {
  new = "new",
  edit = "edit",
}

interface IShopVisitNoteEdit {
  type: TypeShopVisitNote;
}

const ShopVisitNoteEdit: React.FC<IShopVisitNoteEdit> = ({
  type,
}): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const activity = useAppSelector((state: RootState) => state.activity);
  const storeVisit = activity.storeVisit;

  type FormData = {
    note: string;
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormData>();

  useEffect(() => {
    if (!isEmpty(storeVisit)) {
      setValue("note", storeVisit?.note);
    }
  }, [storeVisit]);

  const saveShopVisitNote: SubmitHandler<FormData> = async (data) => {
    await dispatch(saveNote(data.note));
    navigate(ROUTE.SHOP_VISIT_NOTE);
  };

  return (
    <>
      <div className="mt-4" />
      <Header
        title={type === TypeShopVisitNote.new ? `Buat Catatan` : `Edit Catatan`}
      />
      <div className="mt-10" />
      <form onSubmit={handleSubmit(saveShopVisitNote)}>
        <TextArea
          errorMessage={errors.note?.message as string}
          id="shop-visit-note"
          isError={!isEmpty(errors.note)}
          labelTop="Catatan kunjungan hari ini"
          styleWrapper="mt-6"
          register={register("note", {
            required: "Catatan kunjungan wajib diisi",
          })}
        />
        <Button type={TypeButton.submit} title="Simpan" className="my-12" />
      </form>
    </>
  );
};

export default ShopVisitNoteEdit;
