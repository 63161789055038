import React from "react";
import { Button, Header } from "../../components";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../../constants";
import { ExamplePickImage } from "../../assets";

const SelfieGuide: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="px-5">
      <div className="mt-4" />
      <Header title={`Foto Selfie`} />
      <p className="mt-16 text-[#333] text-base">
        Lakukan selfie untuk memulai aktivitas.
      </p>
      <ol className="mt-4 ml-2 text-[#333] text-base">
        <li>1. Ambil selfie di depan toko Posisikan</li>
        <li>2. Posisikan wajah Anda di dalam lingkaran</li>
        <li>3. Pastikan nama toko terlihat dalam foto</li>
      </ol>
      <div className="flex flex-1 my-9 justify-center">
        <img src={ExamplePickImage} alt="example-pick-checkin" />
      </div>
      <Button
        title="Mulai"
        className="mb-9"
        onClick={() => {
          navigate(ROUTE.SELFIE_CHECKIN);
        }}
      />
    </div>
  );
};

export default SelfieGuide;
