import { BrowserRouter } from "react-router-dom";
import RouterWrap from "./router";

const App = () => {
  return (
    <div>
      <div className="my-0 mx-auto min-h-full max-w-screen-sm">
        <div className="my-0 mx-auto min-h-screen max-w-[480px] overflow-x-hidden bg-[#FAFAFA] pb-[66px] relative">
          <BrowserRouter>
            <RouterWrap />
          </BrowserRouter>
        </div>
      </div>
    </div>
  );
};

export default App;
