import { Dispatch } from "redux";
import { IActivityStoreVisit, ICountActivity, ILocation, SET_COUNT_ACTIVITY, SET_LOCATION, SET_STORE_VISIT } from "./activity.types";
import { RootState } from "../store";
import api from "../../services/api";
import { API_ROUTE } from "../../constants/_api_route";
import { isEmpty } from "lodash";
import { getCookie, removeCookie, setCookie } from "../../utils";
import { COOKIES } from "../../constants";
import { setIsLoading } from "../Auth/auth.actions";
import { setDepositMoney } from "../DepositMoney/depositMoney.actions";
import { setSalesOrder } from "../SalesOrder/sales_order.actions";

export const setLocation = (payload: ILocation) => {
  return {
    type: SET_LOCATION,
    payload: payload
  };
};

export const setStoreVisit = (payload: IActivityStoreVisit) => {
  return {
    type: SET_STORE_VISIT,
    payload: payload
  }
}

export const setCountActivity = (payload: ICountActivity) => {
  return {
    type: SET_COUNT_ACTIVITY,
    payload: payload
  }
}

export const getCountActivity = () => {
  return async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true));
    const uuid = getCookie(COOKIES.AUTH_UUID);
    if (!isEmpty(uuid)) {
      const response = await api.post(API_ROUTE.ACTIVITY.COUNT, {
        uuid: uuid
      });

      if (response) {
        const data = response.data.data;
        dispatch(setCountActivity(data));
      }
    }
    dispatch(setIsLoading(false));
  }
}

export const getLocation = () => {
  return async (dispatch: Dispatch) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          dispatch(setLocation({latitude, longitude}));
        },
        (error) => {
          console.error("Error getting geolocation:", error.message);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }
}

export const getDetailStoreVisit = () => {
  return async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true));
    
    const uuid = getCookie(COOKIES.AUTH_UUID);
    const storeVisitId = getCookie(COOKIES.STORE_VISIT_ID);

    if (!isEmpty(uuid) && !isEmpty(storeVisitId)) {
      const response = await api.post(API_ROUTE.STORE_VISIT.DETAIL(storeVisitId), {
        uuid: uuid
      });

      if (response) {
        const data = response.data.data;

        dispatch(setStoreVisit(data));
      }
    }

    dispatch(setIsLoading(false));
  }
}

export const actionCheckin = (storeId: string | number) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    dispatch(setIsLoading(true));
    
    const uuid = getCookie(COOKIES.AUTH_UUID);
    if (!isEmpty(uuid)) {
      const response = await api.post(API_ROUTE.STORE_VISIT.CHECKIN, {
        uuid: uuid,
        store_id: storeId,
        long_checkin: state.activity.location.longitude,
        lat_checkin: state.activity.location.latitude,
      });

      if (response) {
        const data = response.data.data;
        setCookie(COOKIES.STORE_VISIT_ID, data.id);
        removeCookie(COOKIES.SALES_ORDER_ID);
      }
    }

    dispatch(setIsLoading(false));
  }
}

export const actionUploadImage = (image: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true));
    
    const uuid = getCookie(COOKIES.AUTH_UUID);
    const storeVisitId = getCookie(COOKIES.STORE_VISIT_ID);
    if (!isEmpty(uuid) && !isEmpty(storeVisitId)) {
      await api.post(API_ROUTE.STORE_VISIT.SELFIE, {
        uuid: uuid,
        id: storeVisitId,
        image: image
      });
    }
    dispatch(setIsLoading(false));
  }
}

export const saveNote = (note: string | null) => {
  return async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true));
    
    const uuid = getCookie(COOKIES.AUTH_UUID);
    const storeVisitId = getCookie(COOKIES.STORE_VISIT_ID);
    if (!isEmpty(uuid) && !isEmpty(storeVisitId)) {
      await api.post(API_ROUTE.STORE_VISIT.NOTE, {
        uuid: uuid,
        id: storeVisitId,
        note: note
      });
    }
    dispatch(setIsLoading(false));
  }
}

export const actionCheckout = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    dispatch(setIsLoading(true));
    
    const uuid = getCookie(COOKIES.AUTH_UUID);
    const storeVisitId = getCookie(COOKIES.STORE_VISIT_ID);
    
    if (!isEmpty(uuid) && !isEmpty(storeVisitId)) {
      await api.post(API_ROUTE.STORE_VISIT.CHECKOUT, {
        uuid: uuid,
        id: storeVisitId,
        long_checkout: state.activity.location?.longitude,
        lat_checkout: state.activity.location?.latitude,
      });

      removeCookie(COOKIES.STORE_VISIT_ID);
      removeCookie(COOKIES.SALES_ORDER_ID);
      dispatch(setDepositMoney(null))
      dispatch(setSalesOrder(null))
    }

    dispatch(setIsLoading(false));
  }
}