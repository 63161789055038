import React, { useCallback, useEffect } from "react";
import { Button, Header, Input, TypeButton, TypeInput } from "../../components";
import {
  IInventory,
  ISalesOrderDetail,
} from "../../redux/SalesOrder/sales_order.types";
import { ROUTE } from "../../constants";
import { RootState } from "../../redux/store";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  getinventory,
  storeSalesOrder,
} from "../../redux/SalesOrder/sales_order.actions";
import { isEmpty, useAppDispatch, useAppSelector } from "../../utils";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

const SalesOrderNew: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const salesOrder = useAppSelector((state: RootState) => state.salesOrder);
  const d_inventory = salesOrder.d_inventories;
  const inventory = salesOrder.inventories;

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<ISalesOrderDetail>();

  interface IDropdown {
    label: string | number;
    value: string | number;
  }

  type FormData = {
    inventory_id: IDropdown | null;
  };

  const { control: DropdownControl, watch: watchDropdown } =
    useForm<FormData>();

  useEffect(() => {
    dispatch(getinventory());
  }, [dispatch]);

  const getPrice = useCallback(
    (value: ISalesOrderDetail) => {
      let item = inventory.filter((data: IInventory) => {
        return (
          (data.id.toString() as string) ===
          (value.inventory_id.toString() as string)
        );
      });

      if (!isEmpty(item)) {
        const price = item[0].price;
        setValue("price", price);
      }
    },
    [setValue, inventory]
  );

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "inventory_id") {
        getPrice(value as ISalesOrderDetail);
      }
    });

    return () => subscription.unsubscribe();
  }, [getPrice, watch]);

  const inventory_id = watchDropdown("inventory_id");

  useEffect(() => {
    if (!isEmpty(inventory_id)) {
      setValue("inventory_id", inventory_id?.value as string);
    }
  }, [inventory_id, setValue]);

  const createNewOrder: SubmitHandler<ISalesOrderDetail> = async (data) => {
    await dispatch(storeSalesOrder(data));
    navigate(ROUTE.SALES_ORDER);
  };

  return (
    <div className="px-5">
      <div className="mt-4" />
      <Header title={`Buat Sales Order`} />
      <form onSubmit={handleSubmit(createNewOrder)}>
        <div className={`flex flex-col gap-2 mt-16`}>
          <label className="text-[16px] font-bold">Nama Barang</label>
          <Controller
            name="inventory_id"
            control={DropdownControl}
            render={({ field }) => (
              <Select
                {...field}
                options={d_inventory}
                isSearchable={true}
                placeholder="Pilih Barang"
                className="w-full rounded-md text-lg bg-white border-[#CCC]"
                value={{ label: field.value?.label }}
              />
            )}
          />
        </div>
        <input {...register("inventory_id", { required: true })} hidden />
        <div className="flex flex-1 flex-row items-center gap-4 mt-6">
          <Input
            labelTop="Qty"
            isError={!isEmpty(errors.qty)}
            errorMessage={errors.qty?.message as string}
            id="qty"
            type={TypeInput.number}
            register={register("qty", {
              required: "Kuantitas wajib diisi",
            })}
            styleWrapper="w-1/3"
          />
          <Input
            labelTop="Harga Satuan (Rp)"
            isError={!isEmpty(errors.price)}
            errorMessage={errors.price?.message as string}
            id="price"
            type={TypeInput.number}
            register={register("price", {
              required: "Harga wajib diisi",
              disabled: true,
            })}
            styleWrapper="w-2/3"
          />
        </div>
        <Button type={TypeButton.submit} title="Simpan" className="my-9" />
      </form>
    </div>
  );
};

export default SalesOrderNew;
