import React, { useEffect, useState } from "react";
import {
  actionCheckin,
  getLocation,
} from "../../../redux/Activity/activity.actions";
import Select from "react-select";
import { Button, Header, TypeButton } from "../../../components";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { IFarmerStore } from "../../../redux/FarmerStore/farmerStore.types";
import { ROUTE } from "../../../constants";
import { RootState } from "../../../redux/store";
import { getFarmerStoreList } from "../../../redux/FarmerStore/farmerStore.actions";
import { isEmpty, useAppDispatch, useAppSelector } from "../../../utils";
import { useNavigate } from "react-router-dom";

const ActivityCheckinShopVisit: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const farmerStore = useAppSelector((state: RootState) => state.farmerStore);
  const farmerStoreList: IFarmerStore[] = farmerStore.farmerStoreList;

  interface IDropdown {
    label: string | number;
    value: string | number;
  }

  type FormData = {
    storeId: IDropdown | null;
  };

  const {
    control,
    handleSubmit,
  } = useForm<FormData>();

  const [listStore, setListStore] = useState<IDropdown[]>([]);

  useEffect(() => {
    dispatch(getFarmerStoreList());
    dispatch(getLocation());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(farmerStoreList)) {
      const mapToDrodown = farmerStoreList.map((val) => {
        let data: any = [];
        data["label"] = val.name;
        data["value"] = val.id;
        return data;
      });

      setListStore(mapToDrodown);
    }
  }, [farmerStoreList]);

  const checkIn: SubmitHandler<FormData> = async (data) => {
    await dispatch(actionCheckin(data.storeId?.value as string));
    navigate(ROUTE.SELFIE_GUIDE);
  };

  return (
    <>
      <div className="mt-4" />
      <Header title={`Check-in Toko`} />
      <form onSubmit={handleSubmit(checkIn)}>
        <div className={`flex flex-col gap-2 mt-16`}>
          <label className="text-[16px] font-bold">Nama Toko</label>
          <Controller
            name="storeId"
            control={control}
            defaultValue={null}
            render={({ field }) => (
              <Select
                {...field}
                options={listStore}
                isSearchable={true}
                placeholder="Pilih Toko"
                className="w-full rounded-md text-lg bg-white border-[#CCC]"
                value={{ label: field.value?.label }}
              />
            )}
          />
        </div>
        <Button type={TypeButton.submit} title="Selanjutnya" className="my-6" />
      </form>
      <p className="mt-24 text-center text-[#333] text-base">
        Toko tidak terdaftar?{" "}
        <u
          className="text-[#001049] cursor-pointer"
          onClick={() =>
            navigate(`${ROUTE.ACTIVITY_CHECKIN_NEW}?type=shop-visit`)
          }
        >
          Tambah data toko
        </u>
      </p>
    </>
  );
};

export default ActivityCheckinShopVisit;
