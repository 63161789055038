import React from "react";
import { Button } from "../../components";
import { IconNotFound } from "../../assets";
import { ROUTE } from "../../constants";
import { useNavigate } from "react-router-dom";

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col justify-center items-center h-screen px-5">
      <img src={IconNotFound} alt="not-found" height={200} width={200} />
      <p className="text-base font-bold text-[#666] mt-4">
        Halaman tidak ditemukan...
      </p>
      <Button
        title="Kembali"
        onClick={() => navigate(ROUTE.BASE_ROUTE)}
        className="mt-5"
      />
    </div>
  );
};

export default NotFound;
