import api from "../../services/api";
import { API_ROUTE } from "../../constants/_api_route";
import { COOKIES } from "../../constants";
import { Dispatch } from "redux";
import { IFarmerStore, SET_FARMER_STORE, SET_FARMER_STORE_LIST } from "./farmerStore.types";
import { getCookie, isEmpty } from "../../utils";
import { setIsLoading } from "../Auth/auth.actions";

// Action creator to set the farmer store list
export const setFarmerStoreList = (payload: IFarmerStore[] | null) => ({
  type: SET_FARMER_STORE_LIST,
  payload: payload
});

export const setFarmerStore = (payload: IFarmerStore | null) => ({
  type: SET_FARMER_STORE,
  payload: payload
})

// Async action creator to fetch farmer store list from the API
export const getFarmerStoreList = (keyword?: string) => {
  return async (dispatch: Dispatch) => {
    // Set loading state to true
    if (isEmpty(keyword)) {
      dispatch(setIsLoading(true));
    }

    // Retrieve the user's UUID from cookies
    const uuid = getCookie(COOKIES.AUTH_UUID);
    if (!isEmpty(uuid)) {
      try {

        let payload: any = {
          uuid: uuid
        }

        if (!isEmpty(keyword)) {
          payload['keyword'] = keyword
        }

        // Send API request to fetch farmer store list
        const response = await api.post(API_ROUTE.FARMER_STORE.LIST, payload);
        if (response) {
          /**
           * Extract farmer store data from the response &
           * Dispatch the action to set the farmer store list in the store 
           *  */  
          const farmerStoreResponse: IFarmerStore[] = response.data.data
          dispatch(setFarmerStoreList(farmerStoreResponse));
        }

      } catch (error: any) {
        console.log("error getFarmerStoreList: ", error);
      }
    }

    // Set loading state to false after the API call
    dispatch(setIsLoading(false));
  }
};

export const getFarmerStore = (id: string | number) => {
  return async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true));

    const response = await api.post(API_ROUTE.FARMER_STORE.DETAIL(id));

    if (response) {
      const farmerStoreResponse: IFarmerStore = response.data.data;

      dispatch(setFarmerStore(farmerStoreResponse));
    }

    dispatch(setIsLoading(false));
  }
}

export const deleteFarmerStore = (id: string | number) => {
  return async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true));

    const uuid = getCookie(COOKIES.AUTH_UUID);
    if (!isEmpty(uuid)) {
      await api.post(API_ROUTE.FARMER_STORE.DELETE(id), {
        uuid: uuid
      });
    }

    dispatch(setIsLoading(false));
  }
}

export const storeFarmerStore = (data: IFarmerStore) => {
  return async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true));
  
    const uuid = getCookie(COOKIES.AUTH_UUID);
    if (!isEmpty(uuid)) {
      let payload: any = data;
      payload['uuid'] = uuid;
      await api.post(API_ROUTE.FARMER_STORE.STORE, payload);
    }
  
    dispatch(setIsLoading(false));
  }
}

export const editFarmerStore = (data: IFarmerStore) => {
  return async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true));
  
    const uuid = getCookie(COOKIES.AUTH_UUID);
    if (!isEmpty(uuid)) {
      let payload: any = data;
      payload['uuid'] = uuid;
      await api.post(API_ROUTE.FARMER_STORE.EDIT(data.id), payload);
    }
  
    dispatch(setIsLoading(false));
  }
}