import { FarmerStoreInterface, SET_FARMER_STORE, SET_FARMER_STORE_LIST } from "./farmerStore.types";

// Define the initial state of the farmer store
const INITIAL_STATE: FarmerStoreInterface = {
  farmerStoreList: null,
  farmerStore: null,
};

// Define the reducer function for the farmer store
const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_FARMER_STORE_LIST:
      return {
        ...state,
        farmerStoreList: action.payload,
      };
    case SET_FARMER_STORE:
      return {
        ...state,
        farmerStore: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
