export enum TypeDepositMoney {
  cash = "C",
  transfer = "T",
}

export interface IDepositMoney {
  id: number | string;
  type: TypeDepositMoney;
  nominal: number;
  note: string;
  image?: FileList;
}
