export const API_ROUTE = {
  /** CHECK PHONE NUMBER */
  AUTH: {
    CHECK_PHONE: "/check-phone",
    GET_AUTH_BY_UUID: "/get-auth-by-uuid",
  },
  /**ACTIVITY */
  ACTIVITY: {
    COUNT: "/activity/count",
    LIST: "/activity/list"
  },
  /** FARMER STORE */
  FARMER_STORE : {
    LIST: "/farmer-store",
    STORE: "/farmer-store/new",
    DETAIL: (id: number | string) => `/farmer-store/${id}`,
    EDIT: (id: number | string) => `/farmer-store/edit/${id}`,
    DELETE: (id: number | string) => `/farmer-store/delete/${id}`,
  },
  /** ACTIVITY STORE VISIT */
  STORE_VISIT: {
    DETAIL: (id: string | number) => `/activity/store-visit/detail/${id}`,
    CHECKIN: "/activity/store-visit/check-in",
    CHECKOUT: "/activity/store-visit/check-out",
    NOTE: "/activity/store-visit/note",
    SELFIE: "/activity/store-visit/selfie",
    LIST: "/activity/store-visit/list",
  },
  /** INVENTORY */
  INVENTORY: {
    LIST: "/inventory"
  },
  /** SALES ORDER */
  SALES_ORDER: {
    STORE: "/sales-order/store",
    DETAIL: "/sales-order/detail",
    DELETE: "/sales-order/delete",
  },
  /** DEPOSIT MONEY */
  DEPOSIT_MONEY: {
    DETAIL: (id: string | number) => `/activity/deposit-money/detail/${id}`,
    DELETE: (id: string | number) => `/activity/deposit-money/delete/${id}`,
    STORE: "/activity/deposit-money/store",
  }
}