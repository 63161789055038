import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../../constants";

const Splash: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timeout: NodeJS.Timeout = setTimeout(() => {
      navigate(ROUTE.SIGN_IN_PHONE);
    }, 1500);

    return () => {
      clearTimeout(timeout);
    };
  }, [navigate]);

  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <div className="animate-spin rounded-full border-t-4 border-[#001049] border-solid h-12 w-12" />
      <p className="mt-4 text-xs">Loading...</p>
    </div>
  );
};

export default Splash;
