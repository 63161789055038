import { COOKIES } from "../../constants";
import { GET_AUTH, REMOVE_AUTH, SET_IS_AUTHED, SET_IS_LOADING, SET_NAME, SET_PHONE, SET_UUID, SET_VERIFICATION_ID } from "./auth.types";
import { getCookie, isEmpty, removeCookie, setCookie } from "../../utils";
import { Dispatch } from "redux";
import api from "../../services/api";
import { API_ROUTE } from "../../constants/_api_route";

// Action creators for updating isLoading state
export const setIsLoading = (payload: boolean) => {
  return {
    type: SET_IS_LOADING,
    payload: payload
  };
};

// Action creators for updating phone number
export const setPhone = (payload: string | number) => {
  return {
    type: SET_PHONE,
    payload: payload
  };
};

// Action creators for updating name and managing related cookies
export const setName = (payload: string) => {
  if (isEmpty(payload)) {
    removeCookie(COOKIES.AUTH_INFO);
  } else {
    setCookie(COOKIES.AUTH_INFO, payload);
  }
  return {
    type: SET_NAME,
    payload: payload
  };
};

// Action creators for updating verification ID
export const setVerificationId = (payload: string | null) => {
  return {
    type: SET_VERIFICATION_ID,
    payload: payload
  };
};

// Action creators for updating UUID and managing related cookies
export const setUuid = (payload: string | null) => {
  if (isEmpty(payload)) {
    removeCookie(COOKIES.AUTH_UUID);
  } else {
    setCookie(COOKIES.AUTH_UUID, payload);
  }

  return {
    type: SET_UUID,
    payload: payload
  };
};

// Action creators for updating isAuthed state and managing related cookies
export const setAuthed = (payload: boolean) => {
  if (!payload) {
    setUuid(null);
    removeCookie(COOKIES.AUTHED);
  } else {
    setCookie(COOKIES.AUTHED, true);
  }

  return {
    type: SET_IS_AUTHED,
    payload: payload
  };
};

// Action creators for getting authentication status
export const getAuth = () => {
  const uuid: string = getCookie(COOKIES.AUTH_UUID);
  const name: string = getCookie(COOKIES.AUTH_INFO);
  let isAuthed: string | boolean = getCookie(COOKIES.AUTHED);

  if (!isEmpty(isAuthed)) {
    isAuthed = true;
  }

  return {
    type: GET_AUTH,
    payload: {
      isAuthed: !isEmpty(isAuthed) ? true : false,
      name: !isEmpty(name) ? name : null,
      uuid: !isEmpty(uuid) ? uuid : null,
    }
  }
}

// Action creators for removing authentication status and related cookies
export const removeAuth = () => {
  removeCookie(COOKIES.AUTHED);
  removeCookie(COOKIES.AUTH_INFO);
  removeCookie(COOKIES.AUTH_UUID);
  return {type: REMOVE_AUTH};
}

export const validateAuth = () => {
  return async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true));
    const uuid = getCookie(COOKIES.AUTH_UUID);
    if (!isEmpty(uuid)) {
      const response = await api.post(API_ROUTE.AUTH.GET_AUTH_BY_UUID, {
        uuid: uuid
      });

      if (response) {
        dispatch(getAuth());
      } else {
        dispatch(removeAuth());
        dispatch(getAuth());
      }

    }
    dispatch(setIsLoading(false));
  }
}