import { ActivityInterface, SET_COUNT_ACTIVITY, SET_LOCATION, SET_STORE_VISIT } from "./activity.types";

// Define the initial state for the authentication
const INITIAL_STATE: ActivityInterface = {
  location: null,
  storeVisit: null,
  countActivity: null,
};

// Auth reducer to handle different actions and update the state accordingly
const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_LOCATION:
      return {
        ...state,
        location: action.payload
      };
    case SET_STORE_VISIT:
      return {
        ...state,
        storeVisit: action.payload
      };
    case SET_COUNT_ACTIVITY:
      return {
        ...state,
        countActivity: action.payload
      };

    default:
      return state;
  }
};

export default reducer;
