import React, { useCallback, useState } from "react";
import { ArrowLeftSolidWhite, ShutterCamera } from "../../assets";
import { useNavigate, useSearchParams } from "react-router-dom";
import Webcam from "react-webcam";
import { isEmpty, useAppDispatch } from "../../utils";
import { ROUTE } from "../../constants";
import { actionUploadImage } from "../../redux/Activity/activity.actions";

const SelfieCheckin: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [query] = useSearchParams();
  let type: string | null = query.get("type");

  const [image, setImage] = useState<string | undefined>("-asdasdsadsas");

  const webcamRef = React.useRef<Webcam>(null);
  const videoConstraints = {
    facingMode: "user",
  };

  const capture = useCallback(() => {
    if (webcamRef !== null && webcamRef.current !== null) {
      const imageSrc = webcamRef.current.getScreenshot();

      setImage(imageSrc as string);
      navigate(`${ROUTE.SELFIE_CHECKIN}?type=preview`);
    }
  }, [webcamRef, navigate]);

  const upload = async () => {
    await dispatch(actionUploadImage(image as string));
    navigate(ROUTE.SHOP_VISIT);
  };

  return (
    <div className="px-5 bg-black h-screen flex flex-col items-center justify-center overflow-hidden">
      <div className="pt-7 w-full">
        <img
          src={ArrowLeftSolidWhite}
          alt="icon-back"
          onClick={() => navigate(-1)}
        />
      </div>

      {/* Canvas For Camera Preview */}
      {isEmpty(type) && (
        <>
          <div
            className="mt-10 flex-1 bg-white w-full"
            style={{ minHeight: "60vh" }}
          >
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
              screenshotQuality={0.5}
              className="w-full h-full"
            />
          </div>
          <div className="flex flex-1 justify-center py-10">
            <img src={ShutterCamera} alt="shutter-camera" onClick={capture} />
          </div>
        </>
      )}

      {/* Preview Image */}
      {type === "preview" && (
        <>
          <div className="mt-10 bg-white w-full">
            <img
              src={image}
              alt="preview-selfie"
              style={{ maxHeight: "60vh" }}
            />
          </div>
          <div className="w-full flex flex-row items-center justify-center gap-4 py-16">
            <button
              className="text-[#FF0000] border border-[#FF0000] py-3 px-4"
              onClick={() => {
                setImage(undefined);
                navigate(ROUTE.SELFIE_CHECKIN);
              }}
            >
              ULANG
            </button>
            <button
              className="text-[#00964E] border border-[#00964E] py-3 px-7"
              onClick={() => upload()}
            >
              OK
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default SelfieCheckin;
