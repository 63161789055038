import React, { ChangeEvent, Key, useEffect, useState } from "react";
import { BoxListFarmerShop, Header } from "../../components";
import { IFarmerStore } from "../../redux/FarmerStore/farmerStore.types";
import { IconAdd, MagnifyingGlassSolid } from "../../assets";
import { ROUTE } from "../../constants";
import { RootState } from "../../redux/store";
import { getFarmerStoreList } from "../../redux/FarmerStore/farmerStore.actions";
import { isEmpty, useAppDispatch, useAppSelector } from "../../utils";
import { useNavigate } from "react-router-dom";
import _debounce from "lodash/debounce";

const FarmerShopList: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const farmerStore = useAppSelector((state: RootState) => state.farmerStore);
  const farmerStoreList: IFarmerStore[] = farmerStore.farmerStoreList;

  const [keyword, setKeyword] = useState<string>("");

  useEffect(() => {
    dispatch(getFarmerStoreList());
  }, [dispatch]);

  const search = (term: string) => {
    dispatch(getFarmerStoreList(term));
  };

  const debouncedSearch = _debounce(search, 200);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = event.target.value;
    setKeyword(newSearchTerm);
    debouncedSearch(newSearchTerm);
  };

  return (
    <div className="px-5">
      <div className="mt-4" />
      <Header title={`Database Toko Tani`} />
      <div className="flex flex-row w-full mt-7 p-2 rounded-md border-[#CCC] bg-[#fff]">
        <img
          src={MagnifyingGlassSolid}
          alt="magnifying-glass-solid"
          width={15}
          height={15}
        />
        <input
          className="ml-3 flex-1 text-lg focus:outline-none"
          placeholder="Ketik nama customer..."
          value={keyword}
          onChange={handleInputChange}
        />
      </div>
      <div className="mt-14">
        {!isEmpty(farmerStoreList) &&
          farmerStoreList.map((data: IFarmerStore, key: Key) => {
            return (
              <BoxListFarmerShop
                key={key}
                title={data.name}
                onClick={() =>
                  navigate(ROUTE.FARMER_SHOP_DETAIL(data.id as number))
                }
              />
            );
          })}
      </div>
      <div
        className="bg-[#001049] absolute p-3 rounded-full bottom-6 right-6 cursor-pointer"
        onClick={() => navigate(ROUTE.NEW_FARMER_SHOP)}
      >
        <img src={IconAdd} alt="icon-add" width={24} height={24} />
      </div>
    </div>
  );
};

export default FarmerShopList;
