import React, { Key, useEffect, useRef } from "react";
import {
  Button,
  Header,
  Input,
  TextArea,
  TypeButton,
  TypeInput,
} from "../../../components";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../../../constants";
import { isEmpty, useAppDispatch, useAppSelector } from "../../../utils";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { IconCamera } from "../../../assets";
import { isObject } from "lodash";
import { RootState } from "../../../redux/store";
import {
  IDeposit,
  IDepositForm,
} from "../../../redux/DepositMoney/depositMoney.types";
import { storeDepositMoney } from "../../../redux/DepositMoney/depositMoney.actions";

export enum TypeDepositMoneyEdit {
  new = "new",
  edit = "edit",
}

interface IDepositMoneyEdit {
  type: TypeDepositMoneyEdit;
}

const DepositMoneyEdit: React.FC<IDepositMoneyEdit> = ({
  type,
}): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const depositMoney = useAppSelector((state: RootState) => state.depositMoney);
  const _DepositMoney: IDeposit = depositMoney.depositMoney;

  type FormData = {
    type: string;
    nominal: number;
    note: string;
    image?: File;
  };

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormData>();

  const withTypeDeposit = watch("type", "tunai");
  const watchImage = watch("image");
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleClickImage = () => {
    if (fileInputRef.current) {
      fileInputRef.current?.click();
    }
  };

  const saveDepositMoney: SubmitHandler<FormData> = async (data) => {
    const payload: IDepositForm = data as IDepositForm;
    await dispatch(storeDepositMoney(payload));
    navigate(ROUTE.DEPOSIT_MONEY);
  };

  const options = [
    { label: "Tunai", value: "C" },
    { label: "Transfer/Giro", value: "T" },
  ];

  useEffect(() => {
    if (type === TypeDepositMoneyEdit.edit) {
      setValue("type", _DepositMoney.type);
      setValue("nominal", _DepositMoney.nominal as number);
      setValue("note", _DepositMoney.note as string);
    }
  }, [type]);

  return (
    <div className="px-5">
      <div className="mt-4" />
      <Header
        title={
          type === TypeDepositMoneyEdit.new
            ? "Catat Titip Bayar"
            : "Edit Titip Bayar"
        }
      />
      <form onSubmit={handleSubmit(saveDepositMoney)}>
        <div className="flex flex-row justify-around mt-6">
          {options.map((option, key: Key) => (
            <label key={key}>
              <input
                type="radio"
                value={option.value}
                {...register("type", { required: "checkbox" })}
                defaultChecked={option.value === "C"}
              />
              <span className="ml-2 text-[#333] text-base">{option.label}</span>
            </label>
          ))}
        </div>
        <Input
          labelTop="Nominal (Rp)"
          isError={!isEmpty(errors.nominal)}
          errorMessage={errors.nominal?.message as string}
          id="nominal"
          type={TypeInput.number}
          register={register("nominal", {
            required: "Nominal wajib diisi",
          })}
          styleWrapper="mt-6"
        />
        <TextArea
          errorMessage={errors.note?.message as string}
          id="note"
          isError={!isEmpty(errors.note)}
          labelTop="Keterangan"
          styleWrapper="mt-6"
          register={register("note")}
        />
        {withTypeDeposit === "T" && (
          <div className={`flex flex-col gap-2 mt-6`}>
            <label className="text-[16px] font-bold">
              Foto bukti transfer atau giro
            </label>
            <div className="flex flex-row items-center">
              <img
                src={IconCamera}
                alt="camera"
                width={48}
                height={55}
                className="mt-2 cursor-pointer"
                onClick={() => handleClickImage()}
              />
              {!isEmpty(watchImage) && (
                <p className="text-[14px] font-normal text-[#999999] ml-2 mt-2">
                  {watchImage?.name}
                </p>
              )}
            </div>
            <Controller
              name="image"
              control={control}
              render={({ field }) => (
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={(e) => {
                    if (isObject(e.target.files) && !isEmpty(e.target.files)) {
                      field.onChange(e.target.files[0]);
                    }
                  }}
                  accept="image/*"
                  className="hidden"
                />
              )}
            />
          </div>
        )}
        <Button type={TypeButton.submit} title="Simpan" className="my-12" />
      </form>
    </div>
  );
};

export default DepositMoneyEdit;
