import authReducer from "./Auth/auth.reducer";
import farmerStoreReducer from "./FarmerStore/farmerStore.reducer";
import activityReducer from "./Activity/activity.reducer";
import SalesOrder from "./SalesOrder/sales_order.reducer";
import DepositMoney from "./DepositMoney/depositMoney.reducer";
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

// Configure the Redux store with reducers
export const store = configureStore({
  reducer: {
    auth: authReducer,
    farmerStore: farmerStoreReducer,
    activity: activityReducer,
    salesOrder: SalesOrder,
    depositMoney: DepositMoney
  }
});

// Define the type for dispatching actions
export type AppDispatch = typeof store.dispatch;

// Define the type for the root state of the Redux store
export type RootState = ReturnType<typeof store.getState>;

// Define the type for asynchronous thunks
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
