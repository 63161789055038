"use client";
import React, { ChangeEvent } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { isEmpty } from "../../../utils";

export enum TypeInput {
  number = "number",
  tel = "tel",
  text = "text",
}

interface InputProps {
  styleWrapper?: string;
  errorMessage?: string;
  id?: string;
  isError?: boolean;
  isCenterInput?: boolean;
  labelBottom?: string;
  labelTop?: string;
  name?: string;
  min?: number;
  max?: number;
  maxLength?: number;
  minLength?: number;
  onFocus?: any;
  onchange?: (e: ChangeEvent<HTMLInputElement>) => void;
  register?: UseFormRegisterReturn<any> | null;
  type?: TypeInput;
  value?: string;
}

const Input: React.FC<InputProps> = ({
  styleWrapper = "mt-16",
  errorMessage,
  id,
  isCenterInput = false,
  isError = false,
  labelBottom,
  labelTop,
  name,
  min,
  max,
  maxLength,
  minLength,
  onFocus,
  onchange,
  register,
  type = TypeInput.text,
  value,
}): JSX.Element => {
  const classNameInput = `p-2 border rounded-md text-lg focus:outline-none ${
    isError
      ? "border-[#FF0000] focus:border-[#FF0000]"
      : "border-[#CCC] focus:border-[#001049]"
  } ${isCenterInput ? "text-center tracking-[12px]" : ""}`;

  const classNameLabelBottom = `text-[14px] ${
    isError ? "text-[#FF0000]" : "text-[#999999]"
  }`;

  return (
    <div className={`flex flex-col gap-2 ${styleWrapper}`}>
      <label className="text-[16px] font-bold">{labelTop}</label>

      {!register && (
        <input
          className={classNameInput}
          defaultValue={value}
          id={id}
          name={name}
          min={min}
          max={max}
          maxLength={maxLength}
          minLength={minLength}
          onChange={onchange}
          onFocus={onFocus}
          type={type}
        />
      )}

      {register && (
        <input
          className={classNameInput}
          defaultValue={value}
          id={id}
          onFocus={onFocus}
          type={type}
          {...register}
        />
      )}

      {(!isEmpty(labelBottom) || !isEmpty(errorMessage)) && (
        <p className={classNameLabelBottom}>
          {isError ? errorMessage : labelBottom}
        </p>
      )}
    </div>
  );
};

export default Input;
