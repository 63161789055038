export const CITY_DISTRICT = [
  {
    value: "1101",
    province_id: "11",
    label: "KABUPATEN SIMEULUE",
  },
  {
    value: "1102",
    province_id: "11",
    label: "KABUPATEN ACEH SINGKIL",
  },
  {
    value: "1103",
    province_id: "11",
    label: "KABUPATEN ACEH SELATAN",
  },
  {
    value: "1104",
    province_id: "11",
    label: "KABUPATEN ACEH TENGGARA",
  },
  {
    value: "1105",
    province_id: "11",
    label: "KABUPATEN ACEH TIMUR",
  },
  {
    value: "1106",
    province_id: "11",
    label: "KABUPATEN ACEH TENGAH",
  },
  {
    value: "1107",
    province_id: "11",
    label: "KABUPATEN ACEH BARAT",
  },
  {
    value: "1108",
    province_id: "11",
    label: "KABUPATEN ACEH BESAR",
  },
  {
    value: "1109",
    province_id: "11",
    label: "KABUPATEN PIDIE",
  },
  {
    value: "1110",
    province_id: "11",
    label: "KABUPATEN BIREUEN",
  },
  {
    value: "1111",
    province_id: "11",
    label: "KABUPATEN ACEH UTARA",
  },
  {
    value: "1112",
    province_id: "11",
    label: "KABUPATEN ACEH BARAT DAYA",
  },
  {
    value: "1113",
    province_id: "11",
    label: "KABUPATEN GAYO LUES",
  },
  {
    value: "1114",
    province_id: "11",
    label: "KABUPATEN ACEH TAMIANG",
  },
  {
    value: "1115",
    province_id: "11",
    label: "KABUPATEN NAGAN RAYA",
  },
  {
    value: "1116",
    province_id: "11",
    label: "KABUPATEN ACEH JAYA",
  },
  {
    value: "1117",
    province_id: "11",
    label: "KABUPATEN BENER MERIAH",
  },
  {
    value: "1118",
    province_id: "11",
    label: "KABUPATEN PIDIE JAYA",
  },
  {
    value: "1171",
    province_id: "11",
    label: "KOTA BANDA ACEH",
  },
  {
    value: "1172",
    province_id: "11",
    label: "KOTA SABANG",
  },
  {
    value: "1173",
    province_id: "11",
    label: "KOTA LANGSA",
  },
  {
    value: "1174",
    province_id: "11",
    label: "KOTA LHOKSEUMAWE",
  },
  {
    value: "1175",
    province_id: "11",
    label: "KOTA SUBULUSSALAM",
  },
  {
    value: "1201",
    province_id: "12",
    label: "KABUPATEN NIAS",
  },
  {
    value: "1202",
    province_id: "12",
    label: "KABUPATEN MANDAILING NATAL",
  },
  {
    value: "1203",
    province_id: "12",
    label: "KABUPATEN TAPANULI SELATAN",
  },
  {
    value: "1204",
    province_id: "12",
    label: "KABUPATEN TAPANULI TENGAH",
  },
  {
    value: "1205",
    province_id: "12",
    label: "KABUPATEN TAPANULI UTARA",
  },
  {
    value: "1206",
    province_id: "12",
    label: "KABUPATEN TOBA SAMOSIR",
  },
  {
    value: "1207",
    province_id: "12",
    label: "KABUPATEN LABUHAN BATU",
  },
  {
    value: "1208",
    province_id: "12",
    label: "KABUPATEN ASAHAN",
  },
  {
    value: "1209",
    province_id: "12",
    label: "KABUPATEN SIMALUNGUN",
  },
  {
    value: "1210",
    province_id: "12",
    label: "KABUPATEN DAIRI",
  },
  {
    value: "1211",
    province_id: "12",
    label: "KABUPATEN KARO",
  },
  {
    value: "1212",
    province_id: "12",
    label: "KABUPATEN DELI SERDANG",
  },
  {
    value: "1213",
    province_id: "12",
    label: "KABUPATEN LANGKAT",
  },
  {
    value: "1214",
    province_id: "12",
    label: "KABUPATEN NIAS SELATAN",
  },
  {
    value: "1215",
    province_id: "12",
    label: "KABUPATEN HUMBANG HASUNDUTAN",
  },
  {
    value: "1216",
    province_id: "12",
    label: "KABUPATEN PAKPAK BHARAT",
  },
  {
    value: "1217",
    province_id: "12",
    label: "KABUPATEN SAMOSIR",
  },
  {
    value: "1218",
    province_id: "12",
    label: "KABUPATEN SERDANG BEDAGAI",
  },
  {
    value: "1219",
    province_id: "12",
    label: "KABUPATEN BATU BARA",
  },
  {
    value: "1220",
    province_id: "12",
    label: "KABUPATEN PADANG LAWAS UTARA",
  },
  {
    value: "1221",
    province_id: "12",
    label: "KABUPATEN PADANG LAWAS",
  },
  {
    value: "1222",
    province_id: "12",
    label: "KABUPATEN LABUHAN BATU SELATAN",
  },
  {
    value: "1223",
    province_id: "12",
    label: "KABUPATEN LABUHAN BATU UTARA",
  },
  {
    value: "1224",
    province_id: "12",
    label: "KABUPATEN NIAS UTARA",
  },
  {
    value: "1225",
    province_id: "12",
    label: "KABUPATEN NIAS BARAT",
  },
  {
    value: "1271",
    province_id: "12",
    label: "KOTA SIBOLGA",
  },
  {
    value: "1272",
    province_id: "12",
    label: "KOTA TANJUNG BALAI",
  },
  {
    value: "1273",
    province_id: "12",
    label: "KOTA PEMATANG SIANTAR",
  },
  {
    value: "1274",
    province_id: "12",
    label: "KOTA TEBING TINGGI",
  },
  {
    value: "1275",
    province_id: "12",
    label: "KOTA MEDAN",
  },
  {
    value: "1276",
    province_id: "12",
    label: "KOTA BINJAI",
  },
  {
    value: "1277",
    province_id: "12",
    label: "KOTA PADANGSIDIMPUAN",
  },
  {
    value: "1278",
    province_id: "12",
    label: "KOTA GUNUNGSITOLI",
  },
  {
    value: "1301",
    province_id: "13",
    label: "KABUPATEN KEPULAUAN MENTAWAI",
  },
  {
    value: "1302",
    province_id: "13",
    label: "KABUPATEN PESISIR SELATAN",
  },
  {
    value: "1303",
    province_id: "13",
    label: "KABUPATEN SOLOK",
  },
  {
    value: "1304",
    province_id: "13",
    label: "KABUPATEN SIJUNJUNG",
  },
  {
    value: "1305",
    province_id: "13",
    label: "KABUPATEN TANAH DATAR",
  },
  {
    value: "1306",
    province_id: "13",
    label: "KABUPATEN PADANG PARIAMAN",
  },
  {
    value: "1307",
    province_id: "13",
    label: "KABUPATEN AGAM",
  },
  {
    value: "1308",
    province_id: "13",
    label: "KABUPATEN LIMA PULUH KOTA",
  },
  {
    value: "1309",
    province_id: "13",
    label: "KABUPATEN PASAMAN",
  },
  {
    value: "1310",
    province_id: "13",
    label: "KABUPATEN SOLOK SELATAN",
  },
  {
    value: "1311",
    province_id: "13",
    label: "KABUPATEN DHARMASRAYA",
  },
  {
    value: "1312",
    province_id: "13",
    label: "KABUPATEN PASAMAN BARAT",
  },
  {
    value: "1371",
    province_id: "13",
    label: "KOTA PADANG",
  },
  {
    value: "1372",
    province_id: "13",
    label: "KOTA SOLOK",
  },
  {
    value: "1373",
    province_id: "13",
    label: "KOTA SAWAH LUNTO",
  },
  {
    value: "1374",
    province_id: "13",
    label: "KOTA PADANG PANJANG",
  },
  {
    value: "1375",
    province_id: "13",
    label: "KOTA BUKITTINGGI",
  },
  {
    value: "1376",
    province_id: "13",
    label: "KOTA PAYAKUMBUH",
  },
  {
    value: "1377",
    province_id: "13",
    label: "KOTA PARIAMAN",
  },
  {
    value: "1401",
    province_id: "14",
    label: "KABUPATEN KUANTAN SINGINGI",
  },
  {
    value: "1402",
    province_id: "14",
    label: "KABUPATEN INDRAGIRI HULU",
  },
  {
    value: "1403",
    province_id: "14",
    label: "KABUPATEN INDRAGIRI HILIR",
  },
  {
    value: "1404",
    province_id: "14",
    label: "KABUPATEN PELALAWAN",
  },
  {
    value: "1405",
    province_id: "14",
    label: "KABUPATEN S I A K",
  },
  {
    value: "1406",
    province_id: "14",
    label: "KABUPATEN KAMPAR",
  },
  {
    value: "1407",
    province_id: "14",
    label: "KABUPATEN ROKAN HULU",
  },
  {
    value: "1408",
    province_id: "14",
    label: "KABUPATEN BENGKALIS",
  },
  {
    value: "1409",
    province_id: "14",
    label: "KABUPATEN ROKAN HILIR",
  },
  {
    value: "1410",
    province_id: "14",
    label: "KABUPATEN KEPULAUAN MERANTI",
  },
  {
    value: "1471",
    province_id: "14",
    label: "KOTA PEKANBARU",
  },
  {
    value: "1473",
    province_id: "14",
    label: "KOTA D U M A I",
  },
  {
    value: "1501",
    province_id: "15",
    label: "KABUPATEN KERINCI",
  },
  {
    value: "1502",
    province_id: "15",
    label: "KABUPATEN MERANGIN",
  },
  {
    value: "1503",
    province_id: "15",
    label: "KABUPATEN SAROLANGUN",
  },
  {
    value: "1504",
    province_id: "15",
    label: "KABUPATEN BATANG HARI",
  },
  {
    value: "1505",
    province_id: "15",
    label: "KABUPATEN MUARO JAMBI",
  },
  {
    value: "1506",
    province_id: "15",
    label: "KABUPATEN TANJUNG JABUNG TIMUR",
  },
  {
    value: "1507",
    province_id: "15",
    label: "KABUPATEN TANJUNG JABUNG BARAT",
  },
  {
    value: "1508",
    province_id: "15",
    label: "KABUPATEN TEBO",
  },
  {
    value: "1509",
    province_id: "15",
    label: "KABUPATEN BUNGO",
  },
  {
    value: "1571",
    province_id: "15",
    label: "KOTA JAMBI",
  },
  {
    value: "1572",
    province_id: "15",
    label: "KOTA SUNGAI PENUH",
  },
  {
    value: "1601",
    province_id: "16",
    label: "KABUPATEN OGAN KOMERING ULU",
  },
  {
    value: "1602",
    province_id: "16",
    label: "KABUPATEN OGAN KOMERING ILIR",
  },
  {
    value: "1603",
    province_id: "16",
    label: "KABUPATEN MUARA ENIM",
  },
  {
    value: "1604",
    province_id: "16",
    label: "KABUPATEN LAHAT",
  },
  {
    value: "1605",
    province_id: "16",
    label: "KABUPATEN MUSI RAWAS",
  },
  {
    value: "1606",
    province_id: "16",
    label: "KABUPATEN MUSI BANYUASIN",
  },
  {
    value: "1607",
    province_id: "16",
    label: "KABUPATEN BANYU ASIN",
  },
  {
    value: "1608",
    province_id: "16",
    label: "KABUPATEN OGAN KOMERING ULU SELATAN",
  },
  {
    value: "1609",
    province_id: "16",
    label: "KABUPATEN OGAN KOMERING ULU TIMUR",
  },
  {
    value: "1610",
    province_id: "16",
    label: "KABUPATEN OGAN ILIR",
  },
  {
    value: "1611",
    province_id: "16",
    label: "KABUPATEN EMPAT LAWANG",
  },
  {
    value: "1612",
    province_id: "16",
    label: "KABUPATEN PENUKAL ABAB LEMATANG ILIR",
  },
  {
    value: "1613",
    province_id: "16",
    label: "KABUPATEN MUSI RAWAS UTARA",
  },
  {
    value: "1671",
    province_id: "16",
    label: "KOTA PALEMBANG",
  },
  {
    value: "1672",
    province_id: "16",
    label: "KOTA PRABUMULIH",
  },
  {
    value: "1673",
    province_id: "16",
    label: "KOTA PAGAR ALAM",
  },
  {
    value: "1674",
    province_id: "16",
    label: "KOTA LUBUKLINGGAU",
  },
  {
    value: "1701",
    province_id: "17",
    label: "KABUPATEN BENGKULU SELATAN",
  },
  {
    value: "1702",
    province_id: "17",
    label: "KABUPATEN REJANG LEBONG",
  },
  {
    value: "1703",
    province_id: "17",
    label: "KABUPATEN BENGKULU UTARA",
  },
  {
    value: "1704",
    province_id: "17",
    label: "KABUPATEN KAUR",
  },
  {
    value: "1705",
    province_id: "17",
    label: "KABUPATEN SELUMA",
  },
  {
    value: "1706",
    province_id: "17",
    label: "KABUPATEN MUKOMUKO",
  },
  {
    value: "1707",
    province_id: "17",
    label: "KABUPATEN LEBONG",
  },
  {
    value: "1708",
    province_id: "17",
    label: "KABUPATEN KEPAHIANG",
  },
  {
    value: "1709",
    province_id: "17",
    label: "KABUPATEN BENGKULU TENGAH",
  },
  {
    value: "1771",
    province_id: "17",
    label: "KOTA BENGKULU",
  },
  {
    value: "1801",
    province_id: "18",
    label: "KABUPATEN LAMPUNG BARAT",
  },
  {
    value: "1802",
    province_id: "18",
    label: "KABUPATEN TANGGAMUS",
  },
  {
    value: "1803",
    province_id: "18",
    label: "KABUPATEN LAMPUNG SELATAN",
  },
  {
    value: "1804",
    province_id: "18",
    label: "KABUPATEN LAMPUNG TIMUR",
  },
  {
    value: "1805",
    province_id: "18",
    label: "KABUPATEN LAMPUNG TENGAH",
  },
  {
    value: "1806",
    province_id: "18",
    label: "KABUPATEN LAMPUNG UTARA",
  },
  {
    value: "1807",
    province_id: "18",
    label: "KABUPATEN WAY KANAN",
  },
  {
    value: "1808",
    province_id: "18",
    label: "KABUPATEN TULANGBAWANG",
  },
  {
    value: "1809",
    province_id: "18",
    label: "KABUPATEN PESAWARAN",
  },
  {
    value: "1810",
    province_id: "18",
    label: "KABUPATEN PRINGSEWU",
  },
  {
    value: "1811",
    province_id: "18",
    label: "KABUPATEN MESUJI",
  },
  {
    value: "1812",
    province_id: "18",
    label: "KABUPATEN TULANG BAWANG BARAT",
  },
  {
    value: "1813",
    province_id: "18",
    label: "KABUPATEN PESISIR BARAT",
  },
  {
    value: "1871",
    province_id: "18",
    label: "KOTA BANDAR LAMPUNG",
  },
  {
    value: "1872",
    province_id: "18",
    label: "KOTA METRO",
  },
  {
    value: "1901",
    province_id: "19",
    label: "KABUPATEN BANGKA",
  },
  {
    value: "1902",
    province_id: "19",
    label: "KABUPATEN BELITUNG",
  },
  {
    value: "1903",
    province_id: "19",
    label: "KABUPATEN BANGKA BARAT",
  },
  {
    value: "1904",
    province_id: "19",
    label: "KABUPATEN BANGKA TENGAH",
  },
  {
    value: "1905",
    province_id: "19",
    label: "KABUPATEN BANGKA SELATAN",
  },
  {
    value: "1906",
    province_id: "19",
    label: "KABUPATEN BELITUNG TIMUR",
  },
  {
    value: "1971",
    province_id: "19",
    label: "KOTA PANGKAL PINANG",
  },
  {
    value: "2101",
    province_id: "21",
    label: "KABUPATEN KARIMUN",
  },
  {
    value: "2102",
    province_id: "21",
    label: "KABUPATEN BINTAN",
  },
  {
    value: "2103",
    province_id: "21",
    label: "KABUPATEN NATUNA",
  },
  {
    value: "2104",
    province_id: "21",
    label: "KABUPATEN LINGGA",
  },
  {
    value: "2105",
    province_id: "21",
    label: "KABUPATEN KEPULAUAN ANAMBAS",
  },
  {
    value: "2171",
    province_id: "21",
    label: "KOTA B A T A M",
  },
  {
    value: "2172",
    province_id: "21",
    label: "KOTA TANJUNG PINANG",
  },
  {
    value: "3101",
    province_id: "31",
    label: "KABUPATEN KEPULAUAN SERIBU",
  },
  {
    value: "3171",
    province_id: "31",
    label: "KOTA JAKARTA SELATAN",
  },
  {
    value: "3172",
    province_id: "31",
    label: "KOTA JAKARTA TIMUR",
  },
  {
    value: "3173",
    province_id: "31",
    label: "KOTA JAKARTA PUSAT",
  },
  {
    value: "3174",
    province_id: "31",
    label: "KOTA JAKARTA BARAT",
  },
  {
    value: "3175",
    province_id: "31",
    label: "KOTA JAKARTA UTARA",
  },
  {
    value: "3201",
    province_id: "32",
    label: "KABUPATEN BOGOR",
  },
  {
    value: "3202",
    province_id: "32",
    label: "KABUPATEN SUKABUMI",
  },
  {
    value: "3203",
    province_id: "32",
    label: "KABUPATEN CIANJUR",
  },
  {
    value: "3204",
    province_id: "32",
    label: "KABUPATEN BANDUNG",
  },
  {
    value: "3205",
    province_id: "32",
    label: "KABUPATEN GARUT",
  },
  {
    value: "3206",
    province_id: "32",
    label: "KABUPATEN TASIKMALAYA",
  },
  {
    value: "3207",
    province_id: "32",
    label: "KABUPATEN CIAMIS",
  },
  {
    value: "3208",
    province_id: "32",
    label: "KABUPATEN KUNINGAN",
  },
  {
    value: "3209",
    province_id: "32",
    label: "KABUPATEN CIREBON",
  },
  {
    value: "3210",
    province_id: "32",
    label: "KABUPATEN MAJALENGKA",
  },
  {
    value: "3211",
    province_id: "32",
    label: "KABUPATEN SUMEDANG",
  },
  {
    value: "3212",
    province_id: "32",
    label: "KABUPATEN INDRAMAYU",
  },
  {
    value: "3213",
    province_id: "32",
    label: "KABUPATEN SUBANG",
  },
  {
    value: "3214",
    province_id: "32",
    label: "KABUPATEN PURWAKARTA",
  },
  {
    value: "3215",
    province_id: "32",
    label: "KABUPATEN KARAWANG",
  },
  {
    value: "3216",
    province_id: "32",
    label: "KABUPATEN BEKASI",
  },
  {
    value: "3217",
    province_id: "32",
    label: "KABUPATEN BANDUNG BARAT",
  },
  {
    value: "3218",
    province_id: "32",
    label: "KABUPATEN PANGANDARAN",
  },
  {
    value: "3271",
    province_id: "32",
    label: "KOTA BOGOR",
  },
  {
    value: "3272",
    province_id: "32",
    label: "KOTA SUKABUMI",
  },
  {
    value: "3273",
    province_id: "32",
    label: "KOTA BANDUNG",
  },
  {
    value: "3274",
    province_id: "32",
    label: "KOTA CIREBON",
  },
  {
    value: "3275",
    province_id: "32",
    label: "KOTA BEKASI",
  },
  {
    value: "3276",
    province_id: "32",
    label: "KOTA DEPOK",
  },
  {
    value: "3277",
    province_id: "32",
    label: "KOTA CIMAHI",
  },
  {
    value: "3278",
    province_id: "32",
    label: "KOTA TASIKMALAYA",
  },
  {
    value: "3279",
    province_id: "32",
    label: "KOTA BANJAR",
  },
  {
    value: "3301",
    province_id: "33",
    label: "KABUPATEN CILACAP",
  },
  {
    value: "3302",
    province_id: "33",
    label: "KABUPATEN BANYUMAS",
  },
  {
    value: "3303",
    province_id: "33",
    label: "KABUPATEN PURBALINGGA",
  },
  {
    value: "3304",
    province_id: "33",
    label: "KABUPATEN BANJARNEGARA",
  },
  {
    value: "3305",
    province_id: "33",
    label: "KABUPATEN KEBUMEN",
  },
  {
    value: "3306",
    province_id: "33",
    label: "KABUPATEN PURWOREJO",
  },
  {
    value: "3307",
    province_id: "33",
    label: "KABUPATEN WONOSOBO",
  },
  {
    value: "3308",
    province_id: "33",
    label: "KABUPATEN MAGELANG",
  },
  {
    value: "3309",
    province_id: "33",
    label: "KABUPATEN BOYOLALI",
  },
  {
    value: "3310",
    province_id: "33",
    label: "KABUPATEN KLATEN",
  },
  {
    value: "3311",
    province_id: "33",
    label: "KABUPATEN SUKOHARJO",
  },
  {
    value: "3312",
    province_id: "33",
    label: "KABUPATEN WONOGIRI",
  },
  {
    value: "3313",
    province_id: "33",
    label: "KABUPATEN KARANGANYAR",
  },
  {
    value: "3314",
    province_id: "33",
    label: "KABUPATEN SRAGEN",
  },
  {
    value: "3315",
    province_id: "33",
    label: "KABUPATEN GROBOGAN",
  },
  {
    value: "3316",
    province_id: "33",
    label: "KABUPATEN BLORA",
  },
  {
    value: "3317",
    province_id: "33",
    label: "KABUPATEN REMBANG",
  },
  {
    value: "3318",
    province_id: "33",
    label: "KABUPATEN PATI",
  },
  {
    value: "3319",
    province_id: "33",
    label: "KABUPATEN KUDUS",
  },
  {
    value: "3320",
    province_id: "33",
    label: "KABUPATEN JEPARA",
  },
  {
    value: "3321",
    province_id: "33",
    label: "KABUPATEN DEMAK",
  },
  {
    value: "3322",
    province_id: "33",
    label: "KABUPATEN SEMARANG",
  },
  {
    value: "3323",
    province_id: "33",
    label: "KABUPATEN TEMANGGUNG",
  },
  {
    value: "3324",
    province_id: "33",
    label: "KABUPATEN KENDAL",
  },
  {
    value: "3325",
    province_id: "33",
    label: "KABUPATEN BATANG",
  },
  {
    value: "3326",
    province_id: "33",
    label: "KABUPATEN PEKALONGAN",
  },
  {
    value: "3327",
    province_id: "33",
    label: "KABUPATEN PEMALANG",
  },
  {
    value: "3328",
    province_id: "33",
    label: "KABUPATEN TEGAL",
  },
  {
    value: "3329",
    province_id: "33",
    label: "KABUPATEN BREBES",
  },
  {
    value: "3371",
    province_id: "33",
    label: "KOTA MAGELANG",
  },
  {
    value: "3372",
    province_id: "33",
    label: "KOTA SURAKARTA",
  },
  {
    value: "3373",
    province_id: "33",
    label: "KOTA SALATIGA",
  },
  {
    value: "3374",
    province_id: "33",
    label: "KOTA SEMARANG",
  },
  {
    value: "3375",
    province_id: "33",
    label: "KOTA PEKALONGAN",
  },
  {
    value: "3376",
    province_id: "33",
    label: "KOTA TEGAL",
  },
  {
    value: "3401",
    province_id: "34",
    label: "KABUPATEN KULON PROGO",
  },
  {
    value: "3402",
    province_id: "34",
    label: "KABUPATEN BANTUL",
  },
  {
    value: "3403",
    province_id: "34",
    label: "KABUPATEN GUNUNG KIDUL",
  },
  {
    value: "3404",
    province_id: "34",
    label: "KABUPATEN SLEMAN",
  },
  {
    value: "3471",
    province_id: "34",
    label: "KOTA YOGYAKARTA",
  },
  {
    value: "3501",
    province_id: "35",
    label: "KABUPATEN PACITAN",
  },
  {
    value: "3502",
    province_id: "35",
    label: "KABUPATEN PONOROGO",
  },
  {
    value: "3503",
    province_id: "35",
    label: "KABUPATEN TRENGGALEK",
  },
  {
    value: "3504",
    province_id: "35",
    label: "KABUPATEN TULUNGAGUNG",
  },
  {
    value: "3505",
    province_id: "35",
    label: "KABUPATEN BLITAR",
  },
  {
    value: "3506",
    province_id: "35",
    label: "KABUPATEN KEDIRI",
  },
  {
    value: "3507",
    province_id: "35",
    label: "KABUPATEN MALANG",
  },
  {
    value: "3508",
    province_id: "35",
    label: "KABUPATEN LUMAJANG",
  },
  {
    value: "3509",
    province_id: "35",
    label: "KABUPATEN JEMBER",
  },
  {
    value: "3510",
    province_id: "35",
    label: "KABUPATEN BANYUWANGI",
  },
  {
    value: "3511",
    province_id: "35",
    label: "KABUPATEN BONDOWOSO",
  },
  {
    value: "3512",
    province_id: "35",
    label: "KABUPATEN SITUBONDO",
  },
  {
    value: "3513",
    province_id: "35",
    label: "KABUPATEN PROBOLINGGO",
  },
  {
    value: "3514",
    province_id: "35",
    label: "KABUPATEN PASURUAN",
  },
  {
    value: "3515",
    province_id: "35",
    label: "KABUPATEN SIDOARJO",
  },
  {
    value: "3516",
    province_id: "35",
    label: "KABUPATEN MOJOKERTO",
  },
  {
    value: "3517",
    province_id: "35",
    label: "KABUPATEN JOMBANG",
  },
  {
    value: "3518",
    province_id: "35",
    label: "KABUPATEN NGANJUK",
  },
  {
    value: "3519",
    province_id: "35",
    label: "KABUPATEN MADIUN",
  },
  {
    value: "3520",
    province_id: "35",
    label: "KABUPATEN MAGETAN",
  },
  {
    value: "3521",
    province_id: "35",
    label: "KABUPATEN NGAWI",
  },
  {
    value: "3522",
    province_id: "35",
    label: "KABUPATEN BOJONEGORO",
  },
  {
    value: "3523",
    province_id: "35",
    label: "KABUPATEN TUBAN",
  },
  {
    value: "3524",
    province_id: "35",
    label: "KABUPATEN LAMONGAN",
  },
  {
    value: "3525",
    province_id: "35",
    label: "KABUPATEN GRESIK",
  },
  {
    value: "3526",
    province_id: "35",
    label: "KABUPATEN BANGKALAN",
  },
  {
    value: "3527",
    province_id: "35",
    label: "KABUPATEN SAMPANG",
  },
  {
    value: "3528",
    province_id: "35",
    label: "KABUPATEN PAMEKASAN",
  },
  {
    value: "3529",
    province_id: "35",
    label: "KABUPATEN SUMENEP",
  },
  {
    value: "3571",
    province_id: "35",
    label: "KOTA KEDIRI",
  },
  {
    value: "3572",
    province_id: "35",
    label: "KOTA BLITAR",
  },
  {
    value: "3573",
    province_id: "35",
    label: "KOTA MALANG",
  },
  {
    value: "3574",
    province_id: "35",
    label: "KOTA PROBOLINGGO",
  },
  {
    value: "3575",
    province_id: "35",
    label: "KOTA PASURUAN",
  },
  {
    value: "3576",
    province_id: "35",
    label: "KOTA MOJOKERTO",
  },
  {
    value: "3577",
    province_id: "35",
    label: "KOTA MADIUN",
  },
  {
    value: "3578",
    province_id: "35",
    label: "KOTA SURABAYA",
  },
  {
    value: "3579",
    province_id: "35",
    label: "KOTA BATU",
  },
  {
    value: "3601",
    province_id: "36",
    label: "KABUPATEN PANDEGLANG",
  },
  {
    value: "3602",
    province_id: "36",
    label: "KABUPATEN LEBAK",
  },
  {
    value: "3603",
    province_id: "36",
    label: "KABUPATEN TANGERANG",
  },
  {
    value: "3604",
    province_id: "36",
    label: "KABUPATEN SERANG",
  },
  {
    value: "3671",
    province_id: "36",
    label: "KOTA TANGERANG",
  },
  {
    value: "3672",
    province_id: "36",
    label: "KOTA CILEGON",
  },
  {
    value: "3673",
    province_id: "36",
    label: "KOTA SERANG",
  },
  {
    value: "3674",
    province_id: "36",
    label: "KOTA TANGERANG SELATAN",
  },
  {
    value: "5101",
    province_id: "51",
    label: "KABUPATEN JEMBRANA",
  },
  {
    value: "5102",
    province_id: "51",
    label: "KABUPATEN TABANAN",
  },
  {
    value: "5103",
    province_id: "51",
    label: "KABUPATEN BADUNG",
  },
  {
    value: "5104",
    province_id: "51",
    label: "KABUPATEN GIANYAR",
  },
  {
    value: "5105",
    province_id: "51",
    label: "KABUPATEN KLUNGKUNG",
  },
  {
    value: "5106",
    province_id: "51",
    label: "KABUPATEN BANGLI",
  },
  {
    value: "5107",
    province_id: "51",
    label: "KABUPATEN KARANG ASEM",
  },
  {
    value: "5108",
    province_id: "51",
    label: "KABUPATEN BULELENG",
  },
  {
    value: "5171",
    province_id: "51",
    label: "KOTA DENPASAR",
  },
  {
    value: "5201",
    province_id: "52",
    label: "KABUPATEN LOMBOK BARAT",
  },
  {
    value: "5202",
    province_id: "52",
    label: "KABUPATEN LOMBOK TENGAH",
  },
  {
    value: "5203",
    province_id: "52",
    label: "KABUPATEN LOMBOK TIMUR",
  },
  {
    value: "5204",
    province_id: "52",
    label: "KABUPATEN SUMBAWA",
  },
  {
    value: "5205",
    province_id: "52",
    label: "KABUPATEN DOMPU",
  },
  {
    value: "5206",
    province_id: "52",
    label: "KABUPATEN BIMA",
  },
  {
    value: "5207",
    province_id: "52",
    label: "KABUPATEN SUMBAWA BARAT",
  },
  {
    value: "5208",
    province_id: "52",
    label: "KABUPATEN LOMBOK UTARA",
  },
  {
    value: "5271",
    province_id: "52",
    label: "KOTA MATARAM",
  },
  {
    value: "5272",
    province_id: "52",
    label: "KOTA BIMA",
  },
  {
    value: "5301",
    province_id: "53",
    label: "KABUPATEN SUMBA BARAT",
  },
  {
    value: "5302",
    province_id: "53",
    label: "KABUPATEN SUMBA TIMUR",
  },
  {
    value: "5303",
    province_id: "53",
    label: "KABUPATEN KUPANG",
  },
  {
    value: "5304",
    province_id: "53",
    label: "KABUPATEN TIMOR TENGAH SELATAN",
  },
  {
    value: "5305",
    province_id: "53",
    label: "KABUPATEN TIMOR TENGAH UTARA",
  },
  {
    value: "5306",
    province_id: "53",
    label: "KABUPATEN BELU",
  },
  {
    value: "5307",
    province_id: "53",
    label: "KABUPATEN ALOR",
  },
  {
    value: "5308",
    province_id: "53",
    label: "KABUPATEN LEMBATA",
  },
  {
    value: "5309",
    province_id: "53",
    label: "KABUPATEN FLORES TIMUR",
  },
  {
    value: "5310",
    province_id: "53",
    label: "KABUPATEN SIKKA",
  },
  {
    value: "5311",
    province_id: "53",
    label: "KABUPATEN ENDE",
  },
  {
    value: "5312",
    province_id: "53",
    label: "KABUPATEN NGADA",
  },
  {
    value: "5313",
    province_id: "53",
    label: "KABUPATEN MANGGARAI",
  },
  {
    value: "5314",
    province_id: "53",
    label: "KABUPATEN ROTE NDAO",
  },
  {
    value: "5315",
    province_id: "53",
    label: "KABUPATEN MANGGARAI BARAT",
  },
  {
    value: "5316",
    province_id: "53",
    label: "KABUPATEN SUMBA TENGAH",
  },
  {
    value: "5317",
    province_id: "53",
    label: "KABUPATEN SUMBA BARAT DAYA",
  },
  {
    value: "5318",
    province_id: "53",
    label: "KABUPATEN NAGEKEO",
  },
  {
    value: "5319",
    province_id: "53",
    label: "KABUPATEN MANGGARAI TIMUR",
  },
  {
    value: "5320",
    province_id: "53",
    label: "KABUPATEN SABU RAIJUA",
  },
  {
    value: "5321",
    province_id: "53",
    label: "KABUPATEN MALAKA",
  },
  {
    value: "5371",
    province_id: "53",
    label: "KOTA KUPANG",
  },
  {
    value: "6101",
    province_id: "61",
    label: "KABUPATEN SAMBAS",
  },
  {
    value: "6102",
    province_id: "61",
    label: "KABUPATEN BENGKAYANG",
  },
  {
    value: "6103",
    province_id: "61",
    label: "KABUPATEN LANDAK",
  },
  {
    value: "6104",
    province_id: "61",
    label: "KABUPATEN MEMPAWAH",
  },
  {
    value: "6105",
    province_id: "61",
    label: "KABUPATEN SANGGAU",
  },
  {
    value: "6106",
    province_id: "61",
    label: "KABUPATEN KETAPANG",
  },
  {
    value: "6107",
    province_id: "61",
    label: "KABUPATEN SINTANG",
  },
  {
    value: "6108",
    province_id: "61",
    label: "KABUPATEN KAPUAS HULU",
  },
  {
    value: "6109",
    province_id: "61",
    label: "KABUPATEN SEKADAU",
  },
  {
    value: "6110",
    province_id: "61",
    label: "KABUPATEN MELAWI",
  },
  {
    value: "6111",
    province_id: "61",
    label: "KABUPATEN KAYONG UTARA",
  },
  {
    value: "6112",
    province_id: "61",
    label: "KABUPATEN KUBU RAYA",
  },
  {
    value: "6171",
    province_id: "61",
    label: "KOTA PONTIANAK",
  },
  {
    value: "6172",
    province_id: "61",
    label: "KOTA SINGKAWANG",
  },
  {
    value: "6201",
    province_id: "62",
    label: "KABUPATEN KOTAWARINGIN BARAT",
  },
  {
    value: "6202",
    province_id: "62",
    label: "KABUPATEN KOTAWARINGIN TIMUR",
  },
  {
    value: "6203",
    province_id: "62",
    label: "KABUPATEN KAPUAS",
  },
  {
    value: "6204",
    province_id: "62",
    label: "KABUPATEN BARITO SELATAN",
  },
  {
    value: "6205",
    province_id: "62",
    label: "KABUPATEN BARITO UTARA",
  },
  {
    value: "6206",
    province_id: "62",
    label: "KABUPATEN SUKAMARA",
  },
  {
    value: "6207",
    province_id: "62",
    label: "KABUPATEN LAMANDAU",
  },
  {
    value: "6208",
    province_id: "62",
    label: "KABUPATEN SERUYAN",
  },
  {
    value: "6209",
    province_id: "62",
    label: "KABUPATEN KATINGAN",
  },
  {
    value: "6210",
    province_id: "62",
    label: "KABUPATEN PULANG PISAU",
  },
  {
    value: "6211",
    province_id: "62",
    label: "KABUPATEN GUNUNG MAS",
  },
  {
    value: "6212",
    province_id: "62",
    label: "KABUPATEN BARITO TIMUR",
  },
  {
    value: "6213",
    province_id: "62",
    label: "KABUPATEN MURUNG RAYA",
  },
  {
    value: "6271",
    province_id: "62",
    label: "KOTA PALANGKA RAYA",
  },
  {
    value: "6301",
    province_id: "63",
    label: "KABUPATEN TANAH LAUT",
  },
  {
    value: "6302",
    province_id: "63",
    label: "KABUPATEN KOTA BARU",
  },
  {
    value: "6303",
    province_id: "63",
    label: "KABUPATEN BANJAR",
  },
  {
    value: "6304",
    province_id: "63",
    label: "KABUPATEN BARITO KUALA",
  },
  {
    value: "6305",
    province_id: "63",
    label: "KABUPATEN TAPIN",
  },
  {
    value: "6306",
    province_id: "63",
    label: "KABUPATEN HULU SUNGAI SELATAN",
  },
  {
    value: "6307",
    province_id: "63",
    label: "KABUPATEN HULU SUNGAI TENGAH",
  },
  {
    value: "6308",
    province_id: "63",
    label: "KABUPATEN HULU SUNGAI UTARA",
  },
  {
    value: "6309",
    province_id: "63",
    label: "KABUPATEN TABALONG",
  },
  {
    value: "6310",
    province_id: "63",
    label: "KABUPATEN TANAH BUMBU",
  },
  {
    value: "6311",
    province_id: "63",
    label: "KABUPATEN BALANGAN",
  },
  {
    value: "6371",
    province_id: "63",
    label: "KOTA BANJARMASIN",
  },
  {
    value: "6372",
    province_id: "63",
    label: "KOTA BANJAR BARU",
  },
  {
    value: "6401",
    province_id: "64",
    label: "KABUPATEN PASER",
  },
  {
    value: "6402",
    province_id: "64",
    label: "KABUPATEN KUTAI BARAT",
  },
  {
    value: "6403",
    province_id: "64",
    label: "KABUPATEN KUTAI KARTANEGARA",
  },
  {
    value: "6404",
    province_id: "64",
    label: "KABUPATEN KUTAI TIMUR",
  },
  {
    value: "6405",
    province_id: "64",
    label: "KABUPATEN BERAU",
  },
  {
    value: "6409",
    province_id: "64",
    label: "KABUPATEN PENAJAM PASER UTARA",
  },
  {
    value: "6411",
    province_id: "64",
    label: "KABUPATEN MAHAKAM HULU",
  },
  {
    value: "6471",
    province_id: "64",
    label: "KOTA BALIKPAPAN",
  },
  {
    value: "6472",
    province_id: "64",
    label: "KOTA SAMARINDA",
  },
  {
    value: "6474",
    province_id: "64",
    label: "KOTA BONTANG",
  },
  {
    value: "6501",
    province_id: "65",
    label: "KABUPATEN MALINAU",
  },
  {
    value: "6502",
    province_id: "65",
    label: "KABUPATEN BULUNGAN",
  },
  {
    value: "6503",
    province_id: "65",
    label: "KABUPATEN TANA TIDUNG",
  },
  {
    value: "6504",
    province_id: "65",
    label: "KABUPATEN NUNUKAN",
  },
  {
    value: "6571",
    province_id: "65",
    label: "KOTA TARAKAN",
  },
  {
    value: "7101",
    province_id: "71",
    label: "KABUPATEN BOLAANG MONGONDOW",
  },
  {
    value: "7102",
    province_id: "71",
    label: "KABUPATEN MINAHASA",
  },
  {
    value: "7103",
    province_id: "71",
    label: "KABUPATEN KEPULAUAN SANGIHE",
  },
  {
    value: "7104",
    province_id: "71",
    label: "KABUPATEN KEPULAUAN TALAUD",
  },
  {
    value: "7105",
    province_id: "71",
    label: "KABUPATEN MINAHASA SELATAN",
  },
  {
    value: "7106",
    province_id: "71",
    label: "KABUPATEN MINAHASA UTARA",
  },
  {
    value: "7107",
    province_id: "71",
    label: "KABUPATEN BOLAANG MONGONDOW UTARA",
  },
  {
    value: "7108",
    province_id: "71",
    label: "KABUPATEN SIAU TAGULANDANG BIARO",
  },
  {
    value: "7109",
    province_id: "71",
    label: "KABUPATEN MINAHASA TENGGARA",
  },
  {
    value: "7110",
    province_id: "71",
    label: "KABUPATEN BOLAANG MONGONDOW SELATAN",
  },
  {
    value: "7111",
    province_id: "71",
    label: "KABUPATEN BOLAANG MONGONDOW TIMUR",
  },
  {
    value: "7171",
    province_id: "71",
    label: "KOTA MANADO",
  },
  {
    value: "7172",
    province_id: "71",
    label: "KOTA BITUNG",
  },
  {
    value: "7173",
    province_id: "71",
    label: "KOTA TOMOHON",
  },
  {
    value: "7174",
    province_id: "71",
    label: "KOTA KOTAMOBAGU",
  },
  {
    value: "7201",
    province_id: "72",
    label: "KABUPATEN BANGGAI KEPULAUAN",
  },
  {
    value: "7202",
    province_id: "72",
    label: "KABUPATEN BANGGAI",
  },
  {
    value: "7203",
    province_id: "72",
    label: "KABUPATEN MOROWALI",
  },
  {
    value: "7204",
    province_id: "72",
    label: "KABUPATEN POSO",
  },
  {
    value: "7205",
    province_id: "72",
    label: "KABUPATEN DONGGALA",
  },
  {
    value: "7206",
    province_id: "72",
    label: "KABUPATEN TOLI-TOLI",
  },
  {
    value: "7207",
    province_id: "72",
    label: "KABUPATEN BUOL",
  },
  {
    value: "7208",
    province_id: "72",
    label: "KABUPATEN PARIGI MOUTONG",
  },
  {
    value: "7209",
    province_id: "72",
    label: "KABUPATEN TOJO UNA-UNA",
  },
  {
    value: "7210",
    province_id: "72",
    label: "KABUPATEN SIGI",
  },
  {
    value: "7211",
    province_id: "72",
    label: "KABUPATEN BANGGAI LAUT",
  },
  {
    value: "7212",
    province_id: "72",
    label: "KABUPATEN MOROWALI UTARA",
  },
  {
    value: "7271",
    province_id: "72",
    label: "KOTA PALU",
  },
  {
    value: "7301",
    province_id: "73",
    label: "KABUPATEN KEPULAUAN SELAYAR",
  },
  {
    value: "7302",
    province_id: "73",
    label: "KABUPATEN BULUKUMBA",
  },
  {
    value: "7303",
    province_id: "73",
    label: "KABUPATEN BANTAENG",
  },
  {
    value: "7304",
    province_id: "73",
    label: "KABUPATEN JENEPONTO",
  },
  {
    value: "7305",
    province_id: "73",
    label: "KABUPATEN TAKALAR",
  },
  {
    value: "7306",
    province_id: "73",
    label: "KABUPATEN GOWA",
  },
  {
    value: "7307",
    province_id: "73",
    label: "KABUPATEN SINJAI",
  },
  {
    value: "7308",
    province_id: "73",
    label: "KABUPATEN MAROS",
  },
  {
    value: "7309",
    province_id: "73",
    label: "KABUPATEN PANGKAJENE DAN KEPULAUAN",
  },
  {
    value: "7310",
    province_id: "73",
    label: "KABUPATEN BARRU",
  },
  {
    value: "7311",
    province_id: "73",
    label: "KABUPATEN BONE",
  },
  {
    value: "7312",
    province_id: "73",
    label: "KABUPATEN SOPPENG",
  },
  {
    value: "7313",
    province_id: "73",
    label: "KABUPATEN WAJO",
  },
  {
    value: "7314",
    province_id: "73",
    label: "KABUPATEN SIDENRENG RAPPANG",
  },
  {
    value: "7315",
    province_id: "73",
    label: "KABUPATEN PINRANG",
  },
  {
    value: "7316",
    province_id: "73",
    label: "KABUPATEN ENREKANG",
  },
  {
    value: "7317",
    province_id: "73",
    label: "KABUPATEN LUWU",
  },
  {
    value: "7318",
    province_id: "73",
    label: "KABUPATEN TANA TORAJA",
  },
  {
    value: "7322",
    province_id: "73",
    label: "KABUPATEN LUWU UTARA",
  },
  {
    value: "7325",
    province_id: "73",
    label: "KABUPATEN LUWU TIMUR",
  },
  {
    value: "7326",
    province_id: "73",
    label: "KABUPATEN TORAJA UTARA",
  },
  {
    value: "7371",
    province_id: "73",
    label: "KOTA MAKASSAR",
  },
  {
    value: "7372",
    province_id: "73",
    label: "KOTA PAREPARE",
  },
  {
    value: "7373",
    province_id: "73",
    label: "KOTA PALOPO",
  },
  {
    value: "7401",
    province_id: "74",
    label: "KABUPATEN BUTON",
  },
  {
    value: "7402",
    province_id: "74",
    label: "KABUPATEN MUNA",
  },
  {
    value: "7403",
    province_id: "74",
    label: "KABUPATEN KONAWE",
  },
  {
    value: "7404",
    province_id: "74",
    label: "KABUPATEN KOLAKA",
  },
  {
    value: "7405",
    province_id: "74",
    label: "KABUPATEN KONAWE SELATAN",
  },
  {
    value: "7406",
    province_id: "74",
    label: "KABUPATEN BOMBANA",
  },
  {
    value: "7407",
    province_id: "74",
    label: "KABUPATEN WAKATOBI",
  },
  {
    value: "7408",
    province_id: "74",
    label: "KABUPATEN KOLAKA UTARA",
  },
  {
    value: "7409",
    province_id: "74",
    label: "KABUPATEN BUTON UTARA",
  },
  {
    value: "7410",
    province_id: "74",
    label: "KABUPATEN KONAWE UTARA",
  },
  {
    value: "7411",
    province_id: "74",
    label: "KABUPATEN KOLAKA TIMUR",
  },
  {
    value: "7412",
    province_id: "74",
    label: "KABUPATEN KONAWE KEPULAUAN",
  },
  {
    value: "7413",
    province_id: "74",
    label: "KABUPATEN MUNA BARAT",
  },
  {
    value: "7414",
    province_id: "74",
    label: "KABUPATEN BUTON TENGAH",
  },
  {
    value: "7415",
    province_id: "74",
    label: "KABUPATEN BUTON SELATAN",
  },
  {
    value: "7471",
    province_id: "74",
    label: "KOTA KENDARI",
  },
  {
    value: "7472",
    province_id: "74",
    label: "KOTA BAUBAU",
  },
  {
    value: "7501",
    province_id: "75",
    label: "KABUPATEN BOALEMO",
  },
  {
    value: "7502",
    province_id: "75",
    label: "KABUPATEN GORONTALO",
  },
  {
    value: "7503",
    province_id: "75",
    label: "KABUPATEN POHUWATO",
  },
  {
    value: "7504",
    province_id: "75",
    label: "KABUPATEN BONE BOLANGO",
  },
  {
    value: "7505",
    province_id: "75",
    label: "KABUPATEN GORONTALO UTARA",
  },
  {
    value: "7571",
    province_id: "75",
    label: "KOTA GORONTALO",
  },
  {
    value: "7601",
    province_id: "76",
    label: "KABUPATEN MAJENE",
  },
  {
    value: "7602",
    province_id: "76",
    label: "KABUPATEN POLEWALI MANDAR",
  },
  {
    value: "7603",
    province_id: "76",
    label: "KABUPATEN MAMASA",
  },
  {
    value: "7604",
    province_id: "76",
    label: "KABUPATEN MAMUJU",
  },
  {
    value: "7605",
    province_id: "76",
    label: "KABUPATEN MAMUJU UTARA",
  },
  {
    value: "7606",
    province_id: "76",
    label: "KABUPATEN MAMUJU TENGAH",
  },
  {
    value: "8101",
    province_id: "81",
    label: "KABUPATEN MALUKU TENGGARA BARAT",
  },
  {
    value: "8102",
    province_id: "81",
    label: "KABUPATEN MALUKU TENGGARA",
  },
  {
    value: "8103",
    province_id: "81",
    label: "KABUPATEN MALUKU TENGAH",
  },
  {
    value: "8104",
    province_id: "81",
    label: "KABUPATEN BURU",
  },
  {
    value: "8105",
    province_id: "81",
    label: "KABUPATEN KEPULAUAN ARU",
  },
  {
    value: "8106",
    province_id: "81",
    label: "KABUPATEN SERAM BAGIAN BARAT",
  },
  {
    value: "8107",
    province_id: "81",
    label: "KABUPATEN SERAM BAGIAN TIMUR",
  },
  {
    value: "8108",
    province_id: "81",
    label: "KABUPATEN MALUKU BARAT DAYA",
  },
  {
    value: "8109",
    province_id: "81",
    label: "KABUPATEN BURU SELATAN",
  },
  {
    value: "8171",
    province_id: "81",
    label: "KOTA AMBON",
  },
  {
    value: "8172",
    province_id: "81",
    label: "KOTA TUAL",
  },
  {
    value: "8201",
    province_id: "82",
    label: "KABUPATEN HALMAHERA BARAT",
  },
  {
    value: "8202",
    province_id: "82",
    label: "KABUPATEN HALMAHERA TENGAH",
  },
  {
    value: "8203",
    province_id: "82",
    label: "KABUPATEN KEPULAUAN SULA",
  },
  {
    value: "8204",
    province_id: "82",
    label: "KABUPATEN HALMAHERA SELATAN",
  },
  {
    value: "8205",
    province_id: "82",
    label: "KABUPATEN HALMAHERA UTARA",
  },
  {
    value: "8206",
    province_id: "82",
    label: "KABUPATEN HALMAHERA TIMUR",
  },
  {
    value: "8207",
    province_id: "82",
    label: "KABUPATEN PULAU MOROTAI",
  },
  {
    value: "8208",
    province_id: "82",
    label: "KABUPATEN PULAU TALIABU",
  },
  {
    value: "8271",
    province_id: "82",
    label: "KOTA TERNATE",
  },
  {
    value: "8272",
    province_id: "82",
    label: "KOTA TIDORE KEPULAUAN",
  },
  {
    value: "9101",
    province_id: "91",
    label: "KABUPATEN FAKFAK",
  },
  {
    value: "9102",
    province_id: "91",
    label: "KABUPATEN KAIMANA",
  },
  {
    value: "9103",
    province_id: "91",
    label: "KABUPATEN TELUK WONDAMA",
  },
  {
    value: "9104",
    province_id: "91",
    label: "KABUPATEN TELUK BINTUNI",
  },
  {
    value: "9105",
    province_id: "91",
    label: "KABUPATEN MANOKWARI",
  },
  {
    value: "9106",
    province_id: "91",
    label: "KABUPATEN SORONG SELATAN",
  },
  {
    value: "9107",
    province_id: "91",
    label: "KABUPATEN SORONG",
  },
  {
    value: "9108",
    province_id: "91",
    label: "KABUPATEN RAJA AMPAT",
  },
  {
    value: "9109",
    province_id: "91",
    label: "KABUPATEN TAMBRAUW",
  },
  {
    value: "9110",
    province_id: "91",
    label: "KABUPATEN MAYBRAT",
  },
  {
    value: "9111",
    province_id: "91",
    label: "KABUPATEN MANOKWARI SELATAN",
  },
  {
    value: "9112",
    province_id: "91",
    label: "KABUPATEN PEGUNUNGAN ARFAK",
  },
  {
    value: "9171",
    province_id: "91",
    label: "KOTA SORONG",
  },
  {
    value: "9401",
    province_id: "94",
    label: "KABUPATEN MERAUKE",
  },
  {
    value: "9402",
    province_id: "94",
    label: "KABUPATEN JAYAWIJAYA",
  },
  {
    value: "9403",
    province_id: "94",
    label: "KABUPATEN JAYAPURA",
  },
  {
    value: "9404",
    province_id: "94",
    label: "KABUPATEN NABIRE",
  },
  {
    value: "9408",
    province_id: "94",
    label: "KABUPATEN KEPULAUAN YAPEN",
  },
  {
    value: "9409",
    province_id: "94",
    label: "KABUPATEN BIAK NUMFOR",
  },
  {
    value: "9410",
    province_id: "94",
    label: "KABUPATEN PANIAI",
  },
  {
    value: "9411",
    province_id: "94",
    label: "KABUPATEN PUNCAK JAYA",
  },
  {
    value: "9412",
    province_id: "94",
    label: "KABUPATEN MIMIKA",
  },
  {
    value: "9413",
    province_id: "94",
    label: "KABUPATEN BOVEN DIGOEL",
  },
  {
    value: "9414",
    province_id: "94",
    label: "KABUPATEN MAPPI",
  },
  {
    value: "9415",
    province_id: "94",
    label: "KABUPATEN ASMAT",
  },
  {
    value: "9416",
    province_id: "94",
    label: "KABUPATEN YAHUKIMO",
  },
  {
    value: "9417",
    province_id: "94",
    label: "KABUPATEN PEGUNUNGAN BINTANG",
  },
  {
    value: "9418",
    province_id: "94",
    label: "KABUPATEN TOLIKARA",
  },
  {
    value: "9419",
    province_id: "94",
    label: "KABUPATEN SARMI",
  },
  {
    value: "9420",
    province_id: "94",
    label: "KABUPATEN KEEROM",
  },
  {
    value: "9426",
    province_id: "94",
    label: "KABUPATEN WAROPEN",
  },
  {
    value: "9427",
    province_id: "94",
    label: "KABUPATEN SUPIORI",
  },
  {
    value: "9428",
    province_id: "94",
    label: "KABUPATEN MAMBERAMO RAYA",
  },
  {
    value: "9429",
    province_id: "94",
    label: "KABUPATEN NDUGA",
  },
  {
    value: "9430",
    province_id: "94",
    label: "KABUPATEN LANNY JAYA",
  },
  {
    value: "9431",
    province_id: "94",
    label: "KABUPATEN MAMBERAMO TENGAH",
  },
  {
    value: "9432",
    province_id: "94",
    label: "KABUPATEN YALIMO",
  },
  {
    value: "9433",
    province_id: "94",
    label: "KABUPATEN PUNCAK",
  },
  {
    value: "9434",
    province_id: "94",
    label: "KABUPATEN DOGIYAI",
  },
  {
    value: "9435",
    province_id: "94",
    label: "KABUPATEN INTAN JAYA",
  },
  {
    value: "9436",
    province_id: "94",
    label: "KABUPATEN DEIYAI",
  },
  {
    value: "9471",
    province_id: "94",
    label: "KOTA JAYAPURA",
  },
];
