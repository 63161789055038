import React, { useEffect, useState } from "react";
import { BaseModal, Header, HeaderWithButton } from "../../../components";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../../../constants";
import { isEmpty, useAppDispatch, useAppSelector } from "../../../utils";
import { RootState } from "../../../redux/store";
import {
  getDetailStoreVisit,
  saveNote,
} from "../../../redux/Activity/activity.actions";

const ShopVisitNoteReadOnly: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const activity = useAppSelector((state: RootState) => state.activity);
  const storeVisit = activity.storeVisit;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const deleteNote = async () => {
    await dispatch(saveNote(null));
    await dispatch(getDetailStoreVisit());
    setIsModalOpen(false);
  };

  useEffect(() => {
    dispatch(getDetailStoreVisit());
  }, [dispatch]);

  return (
    <>
      <div className="mt-4" />
      {isEmpty(storeVisit?.note) && (
        <>
          <HeaderWithButton
            title={`Catatan`}
            rightMenuTitle="TAMBAH"
            onPressRightMenu={() =>
              navigate(`${ROUTE.SHOP_VISIT_NOTE}?type=new`)
            }
          />
          <p className="text-[#999] text-base text-center mt-14">
            Belum Ada catatan
          </p>
        </>
      )}
      {!isEmpty(storeVisit?.note) && (
        <>
          <Header title={`Catatan`} />
          <p className="text-[#333] text-base mt-14">{storeVisit?.note}</p>

          {/* Action Button */}
          <div className="flex mt-8 justify-end">
            <button
              className="underline text-[#333333] mr-3"
              onClick={() => navigate(`${ROUTE.SHOP_VISIT_NOTE}?type=edit`)}
            >
              Edit
            </button>
            <button
              className="underline text-[#FF0000]"
              onClick={() => setIsModalOpen(true)}
            >
              Hapus
            </button>
          </div>

          {/* Modal */}
          <BaseModal
            heading="Konfirmasi"
            content="Yakin ingin menghapus catatan ini ?"
            isOpen={isModalOpen}
            handleConfirmModal={deleteNote}
            handleCloseModal={() => setIsModalOpen(false)}
          />
        </>
      )}
    </>
  );
};

export default ShopVisitNoteReadOnly;
