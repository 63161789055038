import { Dispatch } from "redux";
import { IDeposit, IDepositForm, SET_DEPOSIT_MONEY } from "./depositMoney.types";
import { setIsLoading } from "../Auth/auth.actions";
import { getCookie } from "../../utils";
import { isEmpty } from "lodash";
import api from "../../services/api";
import { API_ROUTE } from "../../constants/_api_route";
import { COOKIES } from "../../constants";

export const setDepositMoney = (payload: IDeposit | null) => ({
  type: SET_DEPOSIT_MONEY,
  payload: payload
})

export const getDepositMoney = () => {
  return async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true));

    // Retrieve the user's UUID from cookies
    const uuid = getCookie(COOKIES.AUTH_UUID);
    const storeVisitId = getCookie(COOKIES.STORE_VISIT_ID);

    if (!isEmpty(uuid) && !isEmpty(storeVisitId)) {
      try {

        let payload: any = {
          uuid: uuid
        }

        const response = await api.post(API_ROUTE.DEPOSIT_MONEY.DETAIL(storeVisitId), payload);
        if (response) {

          const depositMoneyResponse: IDeposit = response.data.data
          dispatch(setDepositMoney(depositMoneyResponse));
        }

      } catch (error: any) {
        console.log("error getDepositMoney: ", error);
      }
    }
    
    dispatch(setIsLoading(false));
  }
};

export const deleteDepositMoney = () => {
  return async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true));

    // Retrieve the user's UUID from cookies
    const uuid = getCookie(COOKIES.AUTH_UUID);
    const storeVisitId = getCookie(COOKIES.STORE_VISIT_ID);

    if (!isEmpty(uuid) && !isEmpty(storeVisitId)) {
      try {

        let payload: any = {
          uuid: uuid
        }

        const response = await api.post(API_ROUTE.DEPOSIT_MONEY.DELETE(storeVisitId), payload);
        if (response) {
          dispatch(setDepositMoney(null));
        }

      } catch (error: any) {
        console.log("error getDepositMoney: ", error);
      }
    }

    dispatch(setIsLoading(false));
  }
};

export const storeDepositMoney = (payload: IDepositForm) => {
  return async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true));

    // Retrieve the user's UUID from cookies
    const uuid = getCookie(COOKIES.AUTH_UUID);
    const storeVisitId = getCookie(COOKIES.STORE_VISIT_ID);

    if (!isEmpty(uuid) && !isEmpty(storeVisitId)) {
      try {

        let params_object: any = payload;

        params_object['uuid'] = uuid;
        params_object['store_visit_id'] = storeVisitId;

        const response = await api.post(API_ROUTE.DEPOSIT_MONEY.STORE, payload, {}, {}, true);
        if (response) {
          const depositMoneyResponse: IDeposit = response.data.data
          dispatch(setDepositMoney(depositMoneyResponse));
        }

      } catch (error: any) {
        console.log("error getDepositMoney: ", error);
      }
    }
    
    dispatch(setIsLoading(false));
  }
};