import React, { useState } from "react";
import api from "../../services/api";
import { API_ROUTE } from "../../constants/_api_route";
import { Button, Input, TypeButton, TypeInput } from "../../components";
import { ROUTE } from "../../constants";
import { SubmitHandler, useForm } from "react-hook-form";
import { f_auth, f_auth_sdk } from "../../services/firebaseConfig";
import { formatPhoneNumber, isEmpty, useAppDispatch } from "../../utils";
import {
  setName,
  setPhone,
  setUuid,
  setVerificationId,
} from "../../redux/Auth/auth.actions";
import { useNavigate } from "react-router-dom";

const SignInPhone: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [showButton, setShowButton] = useState<boolean>(true);

  // Define form data structure
  type FormDataPhone = {
    phone: string;
  };

  // Initialize form control using react-hook-form
  const {
    register: registerPhone,
    handleSubmit: handleSubmitPhone,
    formState: { errors: errorFormPhone },
    setError: setErrorPhone,
  } = useForm<FormDataPhone>();

  // Validation and submission function
  const validatePhoneNumber: SubmitHandler<FormDataPhone> = async (data) => {
    if (!isEmpty(errorFormPhone)) {
      const formatedPhoneNumber: string = formatPhoneNumber(data.phone);

      const response = await api.post(API_ROUTE.AUTH.CHECK_PHONE, {
        phone: formatedPhoneNumber,
      });

      if (response) {
        const resp = response.data.data;
        try {
          setShowButton(false);

          // Initialize reCAPTCHA verifier
          let verify = new f_auth_sdk.RecaptchaVerifier("recaptcha-container");

          // Sign in with phone number and reCAPTCHA
          const confirmation = await f_auth.signInWithPhoneNumber(
            formatedPhoneNumber,
            verify
          );

          // Dispatch actions to update Redux state
          dispatch(setPhone(data.phone));
          dispatch(setVerificationId(confirmation.verificationId));
          dispatch(setName(resp.name));
          dispatch(setUuid(resp.uuid));

          // Navigate to the OTP confirmation page
          navigate(ROUTE.CONFIRM_OTP);
          return;
        } catch (error: any) {
          setErrorPhone("phone", {
            type: "custom",
            message: "Gagal mengirim kode verifikasi",
          });
          return;
        }
      }
    }

    setErrorPhone("phone", {
      type: "custom",
      message:
        "Nomor HP tidak terdaftar. Isi dengan nomor yang sudah didaftarkan. Hubungi kantor jika mengalami  kendala.",
    });
    return;
  };

  // Render the component
  return (
    <div className="px-5">
      <h1 className="text-[24px] mt-10">Selamat Datang!</h1>
      <p className="text-lg mt-4 max-w-[315px]">
        Silakan masuk dengan nomor HP Anda
      </p>
      <form onSubmit={handleSubmitPhone(validatePhoneNumber)}>
        <Input
          labelTop="Nomor HP"
          labelBottom="Contoh: 08123456789"
          isError={!isEmpty(errorFormPhone.phone)}
          errorMessage={errorFormPhone.phone?.message as string}
          id="phone"
          type={TypeInput.tel}
          register={registerPhone("phone", {
            required: "Nomor telepon wajib diisi",
          })}
        />

        <div
          id="recaptcha-container"
          className={`flex flex-row text-center items-center justify-center ${
            !showButton && "mt-12"
          }`}
        />

        {showButton && (
          <Button
            type={TypeButton.submit}
            title="Selanjutnya"
            className="mt-12"
          />
        )}
      </form>
    </div>
  );
};

export default SignInPhone;
