import { SET_GRAND_TOTAL, SET_INVENTORY, SET_INVENTORY_DROPDOWN, SET_SALES_ORDER, SET_SALES_ORDER_DETAIL, SalesOrderInterface } from "./sales_order.types";

// Define the initial state for the authentication
const INITIAL_STATE: SalesOrderInterface = {
  d_inventories: null,
  inventories: null,
  sales_orders: [],
  sales_order_detail: null,
  grand_total: 0
};

// Auth reducer to handle different actions and update the state accordingly
const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_INVENTORY_DROPDOWN:
      return {
        ...state,
        d_inventories: action.payload
      };
    case SET_INVENTORY:
      return {
        ...state,
        inventories: action.payload
      };
    case SET_SALES_ORDER:
      return {
        ...state,
        sales_orders: action.payload
      };
    case SET_SALES_ORDER_DETAIL:
      return {
        ...state,
        sales_order_detail: action.payload
      };
    case SET_GRAND_TOTAL:
      return {
        ...state,
        grand_total: action.payload
      };

    default:
      return state;
  }
};

export default reducer;
