import React from "react";

interface BoxListFarmerShopProps {
  title: string;
  onClick?: any;
}

const BoxListFarmerShop: React.FC<BoxListFarmerShopProps> = ({
  title,
  onClick,
}) => {
  return (
    <div
      className="border-b py-4 flex flex-row cursor-pointer"
      onClick={onClick}
    >
      <p className="mr-3 text-base text-[#333333]">{title}</p>
      <span>»</span>
    </div>
  );
};

export default BoxListFarmerShop;
