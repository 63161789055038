import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  Button,
  Header,
  Input,
  Select,
  TextArea,
  TypeButton,
} from "../../../components";
import { isEmpty } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../../../constants";
import { IFarmerStore } from "../../../redux/FarmerStore/farmerStore.types";
import { PROVINCE } from "../../../constants/_province";
import { CITY_DISTRICT } from "../../../constants/_city_and_district";

const ActivityCheckinShopVisitNew: React.FC = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFarmerStore>();

  interface IDropdown {
    label: string | number;
    value: string | number;
  }

  const [listProvince, setListProvince] = useState<IDropdown[]>([]);
  const [listCity, setListCity] = useState<IDropdown[]>([]);

  useEffect(() => {
    setListProvince(PROVINCE);
    setListCity(CITY_DISTRICT);
  }, []);

  const addNewFarmerShop: SubmitHandler<IFarmerStore> = (data) => {
    navigate(ROUTE.SELFIE_GUIDE);
  };

  return (
    <>
      <div className="mt-4" />
      <Header title={`Check-in Toko Baru`} />
      <form onSubmit={handleSubmit(addNewFarmerShop)}>
        {/* Store Information */}
        <h1 className="font-bold text-xl text-[#333] my-12">Data Toko</h1>
        <Input
          errorMessage={errors.name?.message as string}
          id="name"
          isError={!isEmpty(errors.name)}
          labelTop="Nama Toko"
          register={register("name", {
            required: "Nama Toko wajib diisi",
          })}
          styleWrapper="mt-0"
        />
        <Input
          errorMessage={errors.owner_name?.message as string}
          id="owner-name"
          isError={!isEmpty(errors.owner_name)}
          labelTop="Nama"
          register={register("owner_name", {
            required: "Nama Wajib Diisi",
          })}
          styleWrapper="mt-6"
        />
        <Input
          errorMessage={errors.phone?.message as string}
          id="phone"
          isError={!isEmpty(errors.phone)}
          labelTop="No. HP / WA"
          register={register("phone", {
            required: "Nomor HP/WA Wajib Diisi",
          })}
          styleWrapper="mt-6"
        />
        <h1 className="font-bold text-xl text-[#333] my-12">Lokasi Toko</h1>
        <Select
          data={listProvince}
          errorMessage={errors.province?.message as string}
          id="province"
          isError={!isEmpty(errors.province)}
          labelTop="Provinsi"
          placeholder="Provinsi"
          register={register("province", {
            required: "Provinsi wajib diisi",
          })}
          styleWrapper="mt-0"
        />
        <Select
          data={listCity}
          errorMessage={errors.city_or_district?.message as string}
          id="city-or-district"
          isError={!isEmpty(errors.city_or_district)}
          labelTop="Kota"
          placeholder="Kota"
          register={register("city_or_district", {
            required: "Kota wajib diisi",
          })}
          styleWrapper="mt-6"
        />
        <TextArea
          errorMessage={errors.address?.message as string}
          id="full-address"
          isError={!isEmpty(errors.address)}
          labelTop="Alamat Lengkap"
          styleWrapper="mt-6"
          register={register("address", {
            required: "Alamat wajib diisi",
          })}
        />

        <Button
          type={TypeButton.submit}
          title="Selanjutnya"
          className="my-12"
        />
      </form>
    </>
  );
};

export default ActivityCheckinShopVisitNew;
