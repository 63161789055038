import React, { useEffect, useState } from "react";
import { BaseModal, Header, HeaderWithButton } from "../../../components";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../../../constants";
import { TypeDepositMoney } from "./../_interface";
import {
  formatRupiah,
  isEmpty,
  useAppDispatch,
  useAppSelector,
} from "../../../utils";
import { RootState } from "../../../redux/store";
import {
  deleteDepositMoney,
  getDepositMoney,
} from "../../../redux/DepositMoney/depositMoney.actions";
import { IDeposit } from "../../../redux/DepositMoney/depositMoney.types";

const DepositMoneyReadOnly: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const depositMoney = useAppSelector((state: RootState) => state.depositMoney);
  const _DepositMoney: IDeposit = depositMoney.depositMoney;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const deleteDeposit = () => {
    dispatch(deleteDepositMoney());
    dispatch(getDepositMoney());
    setIsModalOpen(false);
  };

  useEffect(() => {
    dispatch(getDepositMoney());
  }, []);
  return (
    <div className="px-5">
      <div className="mt-4" />

      {isEmpty(_DepositMoney) && (
        <>
          <HeaderWithButton
            title={`Titip Bayar`}
            rightMenuTitle="TAMBAH"
            onPressRightMenu={() => navigate(`${ROUTE.DEPOSIT_MONEY}?type=new`)}
            customBack
            onPress={() => navigate(ROUTE.SHOP_VISIT)}
          />
          <p className="text-[#999] text-base text-center mt-14">
            Belum ada Titipan Pembayaran
          </p>
        </>
      )}
      {!isEmpty(_DepositMoney) && (
        <>
          <Header
            title={"Titip Bayar"}
            onPress={() => {
              navigate(ROUTE.SHOP_VISIT);
            }}
          />
          <div className="mt-14">
            <h4 className="text-[#666] font-bold text-sm">Nominal</h4>
            <p className="text-[#334]text-base mt-1">
              {!isEmpty(_DepositMoney?.nominal)
                ? formatRupiah(_DepositMoney?.nominal as number)
                : ""}
            </p>
            <h4 className="text-[#666] font-bold text-sm mt-4">
              Jenis Pembayaran
            </h4>
            <p className="text-[#334]text-base mt-1">
              {_DepositMoney?.type === TypeDepositMoney.cash
                ? "Tunai"
                : "Transfer/Giro"}
            </p>
            <h4 className="text-[#666] font-bold text-sm mt-4">Keterangan</h4>
            <p className="text-[#334]text-base mt-1">{_DepositMoney?.note}</p>
            {/* Action Button */}
            <div className="flex mt-6 justify-end">
              <button
                className="underline text-[#333333] mr-3"
                onClick={() => navigate(`${ROUTE.DEPOSIT_MONEY}?type=edit`)}
              >
                Edit
              </button>
              <button
                className="underline text-[#FF0000]"
                onClick={() => setIsModalOpen(true)}
              >
                Hapus
              </button>
            </div>

            {/* Modal */}
            <BaseModal
              heading="Konfirmasi"
              content="Yakin ingin hapus ?"
              isOpen={isModalOpen}
              confirmText="Ya, hapus"
              handleConfirmModal={deleteDeposit}
              handleCloseModal={() => setIsModalOpen(false)}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default DepositMoneyReadOnly;
