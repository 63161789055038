// Auth action types
export const SET_IS_LOADING = "SET_IS_LOADING"
export const SET_PHONE = "SET_PHONE"
export const SET_NAME = "SET_NAME"
export const SET_VERIFICATION_ID = "SET_VERIVICATION_ID"
export const SET_UUID = "SET_UUID"
export const SET_IS_AUTHED = "SET_IS_AUTHED"
export const GET_AUTH = "GET_AUTH"
export const REMOVE_AUTH = "REMOVE_AUTH"

// Auth state interface
export interface AuthInterface {
  isAuthed: boolean,
  name: string | null
  phone: string | number | null,
  uuid: string | null,
  verificationId: string | null,
  isLoading: boolean
}