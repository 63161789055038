import React from "react";

export enum TypeButton {
  submit = "submit",
  button = "button",
}

interface ButtonProps {
  onClick?: any;
  title: string;
  type?: TypeButton;
  className?: string;
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  title,
  type = TypeButton.button,
  className,
}): JSX.Element => {
  return (
    <button
      type={type}
      className={`${className} bg-[#001049] w-full text-center text-white py-2 rounded-md`}
      onClick={onClick}
    >
      {title}
    </button>
  );
};

export default Button;
