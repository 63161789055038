import { Dispatch } from "redux";
import { IInventory, IInventoryDropdown, ISalesOrderDetail, SET_GRAND_TOTAL, SET_INVENTORY, SET_INVENTORY_DROPDOWN, SET_SALES_ORDER, SET_SALES_ORDER_DETAIL } from "./sales_order.types";
import { setIsLoading } from "../Auth/auth.actions";
import api from "../../services/api";
import { API_ROUTE } from "../../constants/_api_route";
import { Key } from "react";
import { getCookie, isEmpty, setCookie } from "../../utils";
import { COOKIES } from "../../constants";

export const setInventoryDropdown = (payload: IInventoryDropdown[] | null) => ({
  type: SET_INVENTORY_DROPDOWN,
  payload: payload
})

export const setInventory = (payload: IInventory[] | null) => ({
  type: SET_INVENTORY,
  payload: payload
})

export const setSalesOrder = (payload: ISalesOrderDetail[] | null) => ({
  type: SET_SALES_ORDER,
  payload: payload
})

export const setSalesOrderDetail = (payload: ISalesOrderDetail | null) => ({
  type: SET_SALES_ORDER_DETAIL,
  payload: payload
})

export const setGrandTotal = (payload: number) => ({
  type: SET_GRAND_TOTAL,
  payload: payload
})

export const getinventory = () => {
  return async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true));

    const response = await api.post(API_ROUTE.INVENTORY.LIST);

    if (response) {
      const data = response.data.data;
      dispatch(setInventory(data));

      let mapItems: IInventoryDropdown[] = data.map((data: IInventory, key: Key) => {
        let dropdown: IInventoryDropdown = { label: data?.name, value: data?.id };
        return dropdown;
      });

      dispatch(setInventoryDropdown(mapItems));
    }


    dispatch(setIsLoading(false));
  }
}

export const storeSalesOrder = (payload: ISalesOrderDetail) => {
  return async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true));
    const uuid = getCookie(COOKIES.AUTH_UUID);
    const storeVisitId = getCookie(COOKIES.STORE_VISIT_ID);
    const salesOrderId = getCookie(COOKIES.SALES_ORDER_ID);

    if (!isEmpty(uuid) && !isEmpty(storeVisitId)) {
      const response = await api.post(API_ROUTE.SALES_ORDER.STORE, {
        uuid: uuid,
        store_visit_id: storeVisitId,
        sales_order_id: !isEmpty(salesOrderId) ? salesOrderId : null,
        inventory_id: payload.inventory_id,
        qty: payload.qty,
        price: payload.price
      });

      if (response) {
        const data = response.data.data;
        dispatch(setGrandTotal(data.grand_total as number));
        if (isEmpty(salesOrderId)) {
          setCookie(COOKIES.SALES_ORDER_ID, data.id);
        }
      }

    }


    dispatch(setIsLoading(false));
  }
}

export const getDetailSalesOrder = (inventory_id?: string | number) => {
  return async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true));
    const salesOrderId = getCookie(COOKIES.SALES_ORDER_ID);
    if (!isEmpty(salesOrderId)) {
      const response = await api.post(API_ROUTE.SALES_ORDER.DETAIL, {
        sales_order_id: salesOrderId,
        inventory_id: inventory_id
      })

      if (response) {
        const data = response.data.data;
        if (!isEmpty(inventory_id)) {
          dispatch(setSalesOrderDetail(data));
        } else {
          dispatch(setSalesOrder(data));
        }
      }
    }
    dispatch(setIsLoading(false));
  }
}

export const deleteSalesOrder = (inventory_id?: string | number) => {
  return async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true));

    const uuid = getCookie(COOKIES.AUTH_UUID);
    const salesOrderId = getCookie(COOKIES.SALES_ORDER_ID);
    if (!isEmpty(salesOrderId) && !isEmpty(uuid)) {
      await api.post(API_ROUTE.SALES_ORDER.DELETE, {
        uuid: uuid,
        sales_order_id: salesOrderId,
        inventory_id: inventory_id
      })
    }

    dispatch(setIsLoading(false));
  }
}