export const SET_INVENTORY = "SET_INVENTORY";
export const SET_INVENTORY_DROPDOWN = "SET_INVENTORY_DROPDOWN";
export const SET_SALES_ORDER = "SET_SALES_ORDER";
export const SET_SALES_ORDER_DETAIL = "SET_SALES_ORDER_DETAIL";
export const SET_GRAND_TOTAL = "SET_GRAND_TOTAL";

export interface IInventoryDropdown {
  label: string | number,
  value: string | number
}

export interface IInventory {
  id: string | number,
  name: string,
  price: number
}

export interface ISalesOrderDetail {
  id: number | string,
  sales_order_id: number | string,
  inventory_id: number | string,
  qty: number | string,
  price: number | string,
  total_price: number | string,
  inven_name?: string,
}

export interface SalesOrderInterface {
  d_inventories: IInventoryDropdown[] | null;
  inventories: IInventory[] | null;
  sales_order_detail: ISalesOrderDetail | null,
  sales_orders: ISalesOrderDetail[] | [],
  grand_total: number | 0
}