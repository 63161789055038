import { DepositMoneyInterface, SET_DEPOSIT_MONEY } from "./depositMoney.types";

// Define the initial state of the farmer store
const INITIAL_STATE: DepositMoneyInterface = {
  depositMoney: null,
};

// Define the reducer function for the farmer store
const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_DEPOSIT_MONEY:
      return {
        ...state,
        depositMoney: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
