export const SET_LOCATION = "SET_LOCATION";
export const SET_STORE_VISIT = "SET_STORE_VISIT";
export const SET_COUNT_ACTIVITY = "SET_COUNT_ACTIVITY";

export interface IObjActivity {
  total: number | null,
  limit: number | null
}
export interface ICountActivity {
  store_visit: IObjActivity,
  farmer_meeting: IObjActivity,
  demo_plot: IObjActivity,
}
export interface ILocation {
  longitude: string | number,
  latitude: string | number
}

export interface IActivityStoreVisit {
  id: number | string,
  sales_id: number | string,
  image?: string,
  long_checkin?: string | number,
  lat_checkin?: string | number,
  long_checkout?: string | number,
  lat_checkout?: string | number,
  checkin_time?: string,
  checkout_time?: string,
  note?: string,
  store_name?: string
}


export interface ActivityInterface {
  location: ILocation | null,
  storeVisit: IActivityStoreVisit | null,
  countActivity: ICountActivity | null
}