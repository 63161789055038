import React from "react";
import { Button } from "../../atoms";

interface BaseModalProps {
  confirmText?: string;
  content: string;
  handleCloseModal: () => void;
  handleConfirmModal: () => void;
  heading: string;
  isOpen: boolean;
}

const BaseModal: React.FC<BaseModalProps> = ({
  confirmText,
  handleCloseModal,
  handleConfirmModal,
  content,
  heading,
  isOpen,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <div className="flex flex-col items-center">
          <h1 className="text-xl font-bold">{heading}</h1>
          <p className="text-base mt-4">{content}</p>
          <Button
            title={confirmText ?? "Ya"}
            className="mt-8"
            onClick={handleConfirmModal}
          />
          <p
            className="mt-5 text-base underline cursor-pointer"
            onClick={handleCloseModal}
          >
            Tidak
          </p>
        </div>
      </div>
    </div>
  );
};

export default BaseModal;
