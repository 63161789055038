import React from "react";
import { isEmpty } from "../../utils";
import DepositMoneyReadOnly from "./Layout/ReadOnly";
import { useSearchParams } from "react-router-dom";
import DepositMoneyEdit, {
  TypeDepositMoneyEdit,
} from "./Layout/EditDepositMoney";

const DepositMoney: React.FC = (): JSX.Element => {
  const [query] = useSearchParams();
  let type: string | null = query.get("type");

  return (
    <div className="px-5">
      {[TypeDepositMoneyEdit.new, TypeDepositMoneyEdit.edit].indexOf(
        type as TypeDepositMoneyEdit
      ) > -1 && (
        <DepositMoneyEdit
          type={
            type === TypeDepositMoneyEdit.new
              ? TypeDepositMoneyEdit.new
              : TypeDepositMoneyEdit.edit
          }
        />
      )}
      {isEmpty(type) && <DepositMoneyReadOnly />}
    </div>
  );
};

export default DepositMoney;
