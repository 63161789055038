export const PROVINCE = [
  {
    value: "11",
    label: "ACEH",
  },
  {
    value: "12",
    label: "SUMATERA UTARA",
  },
  {
    value: "13",
    label: "SUMATERA BARAT",
  },
  {
    value: "14",
    label: "RIAU",
  },
  {
    value: "15",
    label: "JAMBI",
  },
  {
    value: "16",
    label: "SUMATERA SELATAN",
  },
  {
    value: "17",
    label: "BENGKULU",
  },
  {
    value: "18",
    label: "LAMPUNG",
  },
  {
    value: "19",
    label: "KEPULAUAN BANGKA BELITUNG",
  },
  {
    value: "21",
    label: "KEPULAUAN RIAU",
  },
  {
    value: "31",
    label: "DKI JAKARTA",
  },
  {
    value: "32",
    label: "JAWA BARAT",
  },
  {
    value: "33",
    label: "JAWA TENGAH",
  },
  {
    value: "34",
    label: "DI YOGYAKARTA",
  },
  {
    value: "35",
    label: "JAWA TIMUR",
  },
  {
    value: "36",
    label: "BANTEN",
  },
  {
    value: "51",
    label: "BALI",
  },
  {
    value: "52",
    label: "NUSA TENGGARA BARAT",
  },
  {
    value: "53",
    label: "NUSA TENGGARA TIMUR",
  },
  {
    value: "61",
    label: "KALIMANTAN BARAT",
  },
  {
    value: "62",
    label: "KALIMANTAN TENGAH",
  },
  {
    value: "63",
    label: "KALIMANTAN SELATAN",
  },
  {
    value: "64",
    label: "KALIMANTAN TIMUR",
  },
  {
    value: "65",
    label: "KALIMANTAN UTARA",
  },
  {
    value: "71",
    label: "SULAWESI UTARA",
  },
  {
    value: "72",
    label: "SULAWESI TENGAH",
  },
  {
    value: "73",
    label: "SULAWESI SELATAN",
  },
  {
    value: "74",
    label: "SULAWESI TENGGARA",
  },
  {
    value: "75",
    label: "GORONTALO",
  },
  {
    value: "76",
    label: "SULAWESI BARAT",
  },
  {
    value: "81",
    label: "MALUKU",
  },
  {
    value: "82",
    label: "MALUKU UTARA",
  },
  {
    value: "91",
    label: "PAPUA BARAT",
  },
  {
    value: "94",
    label: "PAPUA",
  },
];
