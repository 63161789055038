import React from "react";
import { ArrowLeftSolid } from "../../../assets";
import { useNavigate } from "react-router-dom";

interface HeaderProps {
  customBack?: boolean;
  onPress?: () => void;
  title: String;
}

const Header: React.FC<HeaderProps> = ({
  customBack,
  onPress = () => {},
  title,
}) => {
  const navigate = useNavigate();

  const defaultBack = (): void => {
    navigate(-1);
  };

  return (
    <div className="flex flex-1 flex-row items-center">
      <img
        src={ArrowLeftSolid}
        height={20}
        width={20}
        alt="arrow-left-solid"
        className="cursor-pointer"
        onClick={() => (customBack ? onPress() : defaultBack())}
      />
      <p className="ml-4 font-bold text-[#333333]">{title}</p>
    </div>
  );
};

export default Header;
