import React from "react";

export enum TypeActivityCount {
  kunjungan = "kunjungan",
  demplot = "demplot",
  temutani = "temutani",
}

interface ActivityCountProps {
  type: TypeActivityCount;
  limit: number;
  value: number;
}
const ActivityCount: React.FC<ActivityCountProps> = ({
  type = TypeActivityCount.kunjungan,
  limit,
  value,
}) => {
  let label: string = "";
  switch (type) {
    case TypeActivityCount.demplot:
      label = "DEMPLOT";
      break;
    case TypeActivityCount.temutani:
      label = "TAMU TANI";
      break;
    default:
      label = "KUNJUNGAN";
      break;
  }
  const labelColor = `text-[20px] font-bold mt-1 ${
    value <= limit && "text-[#FF0000]"
  }`;
  return (
    <div className="flex flex-col flex-1">
      <h3 className="text-[12px]">{label}</h3>
      <p className={labelColor}>{value}</p>
    </div>
  );
};

export default ActivityCount;
