import Cookies from "js-cookie";
import { PROVINCE } from "../constants/_province";
import _capitalize from "lodash/capitalize";
import { CITY_DISTRICT } from "../constants/_city_and_district";

// Function to check if data is empty
export function isEmpty(data: any): Boolean {
  let isArray = Array.isArray(data);

  if (data !== null && data !== undefined) {
    if (isArray || typeof data == "string") {
      if (data.length > 0) {
        return false;
      } else {
        return true;
      }
    }

    return false;
  }
  return true;
}

export function formatPhoneNumber(phoneNumber: string) {
  // Remove any non-digit characters
  const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");

  // If the phone number starts with "0", remove it and add "+62"
  if (cleanedPhoneNumber.startsWith("0")) {
    return "+62" + cleanedPhoneNumber.slice(1);
  }

  // If the phone number starts with "+62", return it as is
  if (cleanedPhoneNumber.startsWith("+62")) {
    return cleanedPhoneNumber;
  }

  // Otherwise, assume it's already in the desired format
  return phoneNumber;
}

// Function to convert seconds into duration format (HH:mm:ss)
export function secondToDuration(duration: number): string {
  /**
   * ~~ = Math.floor()
   */
  const hrs = ~~(duration / 3600);
  const mins = ~~((duration % 3600) / 60);
  const secs = ~~duration % 60;

  let ret = "";

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;

  return `${ret}`;
}

// Function to set a cookie with a given name, value, and expiration (in days)
export const setCookie = (
  name: string,
  value: any,
  expiresInDays: number = 365
): void => {
  Cookies.set(name, value, { expires: expiresInDays });
};

// Function to retrieve the value of a cookie by its name
export const getCookie = (name: string): string => {
  const cookie: string | undefined = Cookies.get(name);

  if (cookie !== undefined && !isEmpty(cookie)) {
    return cookie;
  }

  return "";
};

// Function to remove a cookie by its name
export const removeCookie = (name: string): void => {
  Cookies.remove(name);
};

// Function to format a number into Indonesian Rupiah currency format
export function formatRupiah(amount: number): string {
  const formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  });

  return formatter.format(amount);
}

export function getProvinceNameById(id: string | number): string {
  const provinces = PROVINCE;

  const selected_province = provinces.filter((val) => {
    return val.value === `${id}`;
  });

  if (!isEmpty(selected_province)) {
    return `${_capitalize(selected_province[0].label)}` as string;
  }

  return "";
}

export function getDistrictNameById(id: string | number): string {
  const district = CITY_DISTRICT;

  const selected_district = district.filter((val) => {
    return val.value === `${id}`;
  });

  if (!isEmpty(selected_district)) {
    return `${_capitalize(selected_district[0].label)}` as string;
  }

  return "";
}
