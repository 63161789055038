import { AuthInterface, GET_AUTH, REMOVE_AUTH, SET_IS_AUTHED, SET_IS_LOADING, SET_NAME, SET_PHONE, SET_UUID, SET_VERIFICATION_ID } from "./auth.types";

// Define the initial state for the authentication
const INITIAL_STATE: AuthInterface = {
  isAuthed: false,
  isLoading: false,
  name: null,
  phone: null,
  uuid: null,
  verificationId: null,
};

// Auth reducer to handle different actions and update the state accordingly
const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };
    case SET_PHONE:
      return {
        ...state,
        phone: action.payload,
      };
    case SET_NAME:
      return {
        ...state,
        name: action.payload
      }
    case SET_VERIFICATION_ID:
      return {
        ...state,
        verificationId: action.payload,
      };
    case SET_UUID:
      return {
        ...state,
        uuid: action.payload,
      };
    case SET_IS_AUTHED:
      return {
        ...state,
        isAuthed: action.payload,
      };
    case GET_AUTH:
      return {
        ...state,
        isAuthed: action.payload.isAuthed,
        uuid: action.payload.uuid
      };
    case REMOVE_AUTH:
      return INITIAL_STATE

    default:
      return state;
  }
};

export default reducer;
