// Constants for action types
export const SET_FARMER_STORE_LIST: string = "SET_FARMER_STORE_LIST"
export const SET_FARMER_STORE = "SET_FARMER_STORE"

// Interface for individual farmer store data
export interface IFarmerStore {
  id: number | string,
  sales_id: number | string,
  name: string,
  address?: string | null,
  province: number | string,
  city_or_district: number | string | null,
  owner_name?: string | null,
  phone?: string | null,
  created_at: string | null,
  updated_at: string | null
}

// Interface for the farmer store state in Redux
export interface FarmerStoreInterface {
  farmerStoreList: IFarmerStore[] | null,
  farmerStore: IFarmerStore | null,
}