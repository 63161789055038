import React from "react";

const BoxListNotification: React.FC = () => {
  return (
    <div className="py-4 border-b">
      <label className="text-xs text-[#333333]">27/06/22</label>
      <h2 className="text-base font-bold text-[#333333] mt-1">
        Promo Sprayer bulan Juli
      </h2>
      <p className="text-base text-[#333333] mt-2">
        Ada promo sprayer untuk bulan Juli. Diskon 15% jika beli sprayer
        sekaligus pestisida.
      </p>
    </div>
  );
};

export default BoxListNotification;
