import React from "react";

const IsLoading: React.FC = (): JSX.Element => {
  return (
    <div className="absolute w-full h-full top-0 flex flex-1 flex-col justify-center items-center bg-black bg-opacity-30">
      <div className="animate-spin rounded-full border-t-4 border-[#001049] border-solid h-12 w-12" />
      <p className="mt-5 text-xs font-semibold text-[#333]">Loading...</p>
    </div>
  );
};

export default IsLoading;
