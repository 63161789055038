import React from "react";
import {
  CircleArrowRightSolid,
  IconDemplot,
  IconKunjungan,
  IconTemuTani,
} from "../../../assets";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../../../constants";

export enum TypeBoxActivity {
  kunjungan = "kunjungan",
  demplot = "demplot",
  temutani = "temutani",
}

interface BoxActivityProps {
  type: TypeBoxActivity;
  disabled?: Boolean;
}

const BoxActivity: React.FC<BoxActivityProps> = ({
  type = TypeBoxActivity.kunjungan,
  disabled = false,
}): JSX.Element => {
  const navigate = useNavigate();
  let IconBox: string = "";
  switch (type) {
    case TypeBoxActivity.demplot:
      IconBox = IconDemplot;
      break;
    case TypeBoxActivity.temutani:
      IconBox = IconTemuTani;
      break;
    default:
      IconBox = IconKunjungan;
      break;
  }

  let label: string = "";
  switch (type) {
    case TypeBoxActivity.demplot:
      label = "Demo plot";
      break;
    case TypeBoxActivity.temutani:
      label = "Temu tani";
      break;
    default:
      label = "Kunjungan";
      break;
  }

  const onClick = () => {
    switch (type) {
      case TypeBoxActivity.demplot:
        !disabled && navigate(`${ROUTE.ACTIVITY_CHECKIN}?type=demo-plot`);
        break;
      case TypeBoxActivity.temutani:
        !disabled && navigate(`${ROUTE.ACTIVITY_CHECKIN}?type=farmer-meeting`);
        break;
      default:
        !disabled && navigate(`${ROUTE.ACTIVITY_CHECKIN}?type=shop-visit`);
        break;
    }
  };

  return (
    <div
      className={`flex flex-1 flex-col py-4 rounded-md items-center gap-3 ${
        !disabled ? "bg-[#5526BA] cursor-pointer" : "bg-[#CCCCCC]"
      }`}
      onClick={onClick}
    >
      <img src={IconBox} alt="img-box" width={40} height={40} />
      <p className="text-center text-white text-sm">{label}</p>
      <img
        src={CircleArrowRightSolid}
        width={15}
        height={15}
        alt="arrow-right"
      />
    </div>
  );
};

export default BoxActivity;
