import React, { useCallback, useEffect } from "react";
import {
  ActivityCheckin,
  ActivityCheckinNew,
  ActivityList,
  ConfirmOTP,
  DepositMoney,
  EditFarmerShop,
  FarmerShop,
  FarmerShopList,
  Home,
  NewFarmerShop,
  NotFound,
  NotificationDetail,
  NotificationList,
  SalesOrder,
  SalesOrderEdit,
  SalesOrderNew,
  SelfieCheckin,
  SelfieGuide,
  ShopVisit,
  ShopVisitNote,
  SignInPhone,
  Splash,
} from "../pages";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { COOKIES, ROUTE, ROUTE_PATH } from "../constants";
import { getAuth, validateAuth } from "../redux/Auth/auth.actions";
import IsLoading from "./Layout/IsLoading";
import { getCookie, isEmpty, useAppDispatch, useAppSelector } from "../utils";
import { RootState } from "../redux/store";

const Router: React.FC = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const auth = useAppSelector((state: RootState) => state.auth);
  const dispatch = useAppDispatch();
  const storeVisitId = getCookie(COOKIES.STORE_VISIT_ID);
  const isDoingActivities = !isEmpty(storeVisitId);

  const validateAuthCallback = useCallback(async () => {
    dispatch(validateAuth());
    if (!auth.isAuthed) {
      navigate(ROUTE.BASE_ROUTE);
    }
  }, [dispatch]);

  useEffect(() => {
    validateAuthCallback();
  }, [validateAuthCallback]);

  const handleToStoreVisit = useCallback(() => {
    let pathShopVisit = location.pathname.indexOf(ROUTE_PATH.SHOP_VISIT) > -1;
    let pathSelfieGuide =
      location.pathname.indexOf(ROUTE_PATH.SELFIE_GUIDE) > -1;
    let pathSelfieCheckin =
      location.pathname.indexOf(ROUTE_PATH.SELFIE_CHECKIN) > -1;
    let pathSalesOrder = location.pathname.indexOf(ROUTE_PATH.SALES_ORDER) > -1;
    let pathDepositMoney =
      location.pathname.indexOf(ROUTE_PATH.DEPOSIT_MONEY) > -1;

    if (
      !pathShopVisit &&
      !pathSalesOrder &&
      !pathDepositMoney &&
      !pathSelfieGuide &&
      !pathSelfieCheckin
    ) {
      navigate(ROUTE_PATH.SHOP_VISIT, {
        replace: true,
      });
    }
  }, [location, navigate]);

  useEffect(() => {
    if (isDoingActivities) {
      handleToStoreVisit();
    }
  }, [isDoingActivities, handleToStoreVisit]);

  return (
    <>
      <Routes>
        {/* NOT AUTHED */}
        {!auth.isAuthed && (
          <>
            <Route path={ROUTE_PATH.BASE_ROUTE} element={<Splash />} />
            <Route path={ROUTE_PATH.SIGN_IN_PHONE} element={<SignInPhone />} />
            <Route path={ROUTE_PATH.CONFIRM_OTP} element={<ConfirmOTP />} />
          </>
        )}

        {/* AUTHED */}
        {auth.isAuthed && (
          <>
            {!isDoingActivities && (
              <>
                {/* DASHBOARD */}
                <Route path={ROUTE_PATH.BASE_ROUTE} element={<Home />} />
                <Route
                  path={ROUTE_PATH.ACTIVITY_LIST}
                  element={<ActivityList />}
                />

                {/* NOTIFICATION */}
                <Route
                  path={ROUTE_PATH.NOTIFICATIONS}
                  element={<NotificationList />}
                />
                <Route
                  path={ROUTE_PATH.NOTIFICATION_DETAIL}
                  element={<NotificationDetail />}
                />
                {/* FARMER SHOP */}
                <Route
                  path={ROUTE_PATH.FARMER_SHOPS}
                  element={<FarmerShopList />}
                />
                <Route
                  path={ROUTE_PATH.FARMER_SHOP_DETAIL}
                  element={<FarmerShop />}
                />
                <Route
                  path={ROUTE_PATH.NEW_FARMER_SHOP}
                  element={<NewFarmerShop />}
                />
                <Route
                  path={ROUTE_PATH.EDIT_FARMER_SHOP}
                  element={<EditFarmerShop />}
                />
                {/* ACTIVITY CHECKIN */}
                <Route
                  path={ROUTE_PATH.ACTIVITY_CHECKIN}
                  element={<ActivityCheckin />}
                />
                <Route
                  path={ROUTE_PATH.ACTIVITY_CHECKIN_NEW}
                  element={<ActivityCheckinNew />}
                />
              </>
            )}

            {isDoingActivities && (
              <>
                <Route
                  path={ROUTE_PATH.SELFIE_GUIDE}
                  element={<SelfieGuide />}
                />
                <Route
                  path={ROUTE_PATH.SELFIE_CHECKIN}
                  element={<SelfieCheckin />}
                />
                {/* SHOP VISIT */}
                <Route path={ROUTE_PATH.SHOP_VISIT} element={<ShopVisit />} />
                <Route
                  path={ROUTE_PATH.SHOP_VISIT_NOTE}
                  element={<ShopVisitNote />}
                />
                {/* SALES ORDER */}
                <Route path={ROUTE_PATH.SALES_ORDER} element={<SalesOrder />} />
                <Route
                  path={ROUTE_PATH.SALES_ORDER_NEW}
                  element={<SalesOrderNew />}
                />
                <Route
                  path={ROUTE_PATH.SALES_ORDER_EDIT}
                  element={<SalesOrderEdit />}
                />
                {/* DEPOSIT MONEY */}
                <Route
                  path={ROUTE_PATH.DEPOSIT_MONEY}
                  element={<DepositMoney />}
                />
              </>
            )}
          </>
        )}

        <Route path="*" element={<NotFound />} />
      </Routes>
      {auth.isLoading && <IsLoading />}
    </>
  );
};

export default Router;
