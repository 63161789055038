import React, { useEffect, useState } from "react";
import {
  ActivityCount,
  BaseModal,
  BoxActivity,
  TypeActivityCount,
  TypeBoxActivity,
} from "../../components";
import { AvatarProfileDefault, IconInbox, IconInboxNew } from "../../assets";
import { ROUTE } from "../../constants";
import { RootState } from "../../redux/store";
import { To, useNavigate } from "react-router-dom";
import { removeAuth } from "../../redux/Auth/auth.actions";
import { useAppDispatch, useAppSelector } from "../../utils";
import { getCountActivity } from "../../redux/Activity/activity.actions";
import { ICountActivity } from "../../redux/Activity/activity.types";

const Home: React.FC = () => {
  const navigate = useNavigate();

  const authed = useAppSelector((state: RootState) => state.auth);
  const activity = useAppSelector((state: RootState) => state.activity);
  const countActivity: ICountActivity = activity.countActivity;
  const dispatch = useAppDispatch();

  const [hasNewMessage] = useState<boolean>(false);
  const [isModalLogoutOpen, setIsModalLogoutOpen] = useState<boolean>(false);

  const redirectTo = (path: To) => {
    navigate(path);
  };

  useEffect(() => {
    dispatch(getCountActivity());
  }, [dispatch]);

  return (
    <div className="px-5">
      <div className="flex flex-row justify-between items-center mt-4">
        <img
          src={AvatarProfileDefault}
          alt="avatar-profile"
          width={30}
          height={30}
        />
        <img
          src={hasNewMessage ? IconInboxNew : IconInbox}
          alt="icon-inbox"
          className="cursor-pointer"
          onClick={() => redirectTo(ROUTE.NOTIFICATIONS)}
          width={30}
          height={30}
        />
      </div>

      <h1 className="text-xl font-bold mt-14">
        Selamat siang, <br /> {authed.name}
      </h1>

      <p className="text-base mt-4">Aktivitas apa yang ingin dilakukan</p>
      <div className="flex flex-row mt-5 gap-4">
        <BoxActivity type={TypeBoxActivity.kunjungan} />
        <BoxActivity type={TypeBoxActivity.demplot} disabled />
        <BoxActivity type={TypeBoxActivity.temutani} disabled />
      </div>

      <p className="mt-14 text-sm">Agustus 2023</p>
      <h1 className="mt-1 text-lg font-bold">Pencapaian bulan ini</h1>

      <div className="flex flex-row mt-4">
        <ActivityCount
          type={TypeActivityCount.kunjungan}
          limit={countActivity?.store_visit.limit as number}
          value={countActivity?.store_visit.total as number}
        />
        <ActivityCount type={TypeActivityCount.demplot} limit={0} value={0} />
        <ActivityCount type={TypeActivityCount.temutani} limit={0} value={0} />
      </div>
      <p
        className="text-sm mt-4 underline cursor-pointer"
        onClick={() => navigate(ROUTE.ACTIVITY_LIST)}
      >
        Lihat riwayat »
      </p>

      <h2 className="mt-16 text-lg font-bold">Informasi lainnya</h2>

      <p
        className="mt-7 text-base cursor-pointer"
        onClick={() => redirectTo(ROUTE.FARMER_SHOPS)}
      >
        Database toko tani »
      </p>
      <p
        className="mt-8 text-base underline text-[#FF0000] cursor-pointer"
        onClick={(e) => {
          e.preventDefault();
          setIsModalLogoutOpen(true);
        }}
      >
        Keluar dari aplikasi »
      </p>
      <BaseModal
        heading="Konfirmasi"
        content="Yakin ingin keluar dari aplikasi ?"
        isOpen={isModalLogoutOpen}
        handleConfirmModal={() => {
          dispatch(removeAuth());
          navigate(ROUTE.BASE_ROUTE);
        }}
        handleCloseModal={() => setIsModalLogoutOpen(false)}
      />
    </div>
  );
};

export default Home;
